<template>
  <div class="mb-4" v-bind="$attrs">
    {{ $t('ui.bug_reporting.console_errors') }}
  </div>
  <div
    class="rounded px-2 py-1 text-sm max-h-60 overflow-auto"
    :class="errors.length ? 'bg-red-alpha' : 'bg-background'"
  >
    <div v-if="!errors.length" class="text-secondary text-center">
      {{ $t('ui.bug_reporting.no_errors') }}
    </div>
    <div
      v-for="(error, idx) in errors"
      :key="idx"
      class="font-mono"
      :class="{ 'mt-4': idx > 0 }"
    >
      [{{ format(error.date, 'yyyy-MM-dd HH:mm:ss') }}] {{ error.message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { ConsoleError } from './bug_report'
import { format } from 'date-fns'

defineProps({
  errors: {
    type: Array as PropType<ConsoleError[]>,
    default: () => [],
  },
})
</script>
