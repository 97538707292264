<template>
  <template v-for="(relation, i) in sortedRelations" :key="i">
    <hr v-if="dense && i != 0" class="border-t-4" />
    <ExpandPanel
      :id="`select-root-${relation.root_id}`"
      v-model="expandedPanels[i]"
      :disable-highlight="dense"
      @toggled="(expanded) => expanded && closeOthers(i)"
    >
      <template #title>
        <Avatar
          :entity="{ id: relation.root_id, text: relation.root_name }"
          :size="dense ? null : 'lg'"
          :class="{ 'mr-3.5': dense }"
        />
        <span :class="{ 'text-base': dense }">
          {{ relation.root_name }}
        </span>
      </template>
      <div :class="dense ? 'mx-3' : 'mt-2 mx-4'">
        <EntityRolePanels
          :root-relation="relation"
          :initial-expand-index="0"
          :class="{ 'text-secondary': dense }"
          @start-session="(data) => emit('startSession', data)"
        />
      </div>
    </ExpandPanel>
  </template>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import ExpandPanel from '@/components/misc/ExpandPanel'
import Avatar from '@/components/misc/Avatar'
import EntityRolePanels from '@/components/navigation/components/EntityRolePanels.vue'

import type { PropType } from 'vue'
import type { StartSessionItem } from '@/components/navigation/components/EntityRolePanels.vue'
import type { UserRootRelation } from '@/types/services/user'

const props = defineProps({
  relations: {
    type: Array as PropType<UserRootRelation[]>,
    default: () => [],
  },
  current: {
    type: Object as PropType<UserRootRelation | null>,
    default: null,
  },
  dense: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  startSession: [sessionItem: StartSessionItem]
}>()

const expandedPanels = ref<boolean[]>([])

const sortedRelations = computed(() =>
  [...props.relations].sort((a, b) => {
    return a.root_name.localeCompare(b.root_name)
  })
)

watch(
  sortedRelations,
  (relations: UserRootRelation[]) => {
    expandedPanels.value = []
    relations.forEach((rel, idx) =>
      expandedPanels.value.push(
        props.current ? props.current.root_id == rel.root_id : idx == 0
      )
    )
  },
  { immediate: true }
)

const closeOthers = (idx: number) => {
  for (let i = 0; i < expandedPanels.value.length; i++) {
    if (i != idx) {
      expandedPanels.value[i] = false
    }
  }
}
</script>
