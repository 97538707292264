import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueHotjar from 'vue-hotjar-next'
import VueTippy from 'vue-tippy'
import { isCancel } from 'axios'
import { defaultProps as vueTipppyDefaultProps } from '@/constants/vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import * as Sentry from '@sentry/vue'

import { API_URL, releaseVersion } from '@/helpers/system'
import i18n from '@/langs'
import breakpoints from '@/plugins/Breakpoints'
import router from '@/router/index'
import { useEventBus } from '@/composables/event_bus'
import { useTestMode } from '@/composables/test_mode'
import '@/validation/rules'

import App from '@/App.vue'

import '@/assets/style/main.scss'

import ApiService from '@/services/api.service'
import TokenService from '@/services/user/token'

const isLocalhost =
  location.hostname === 'localhost' ||
  location.hostname === '127.0.0.1' ||
  location.hostname === ''

if (!isLocalhost && !location.hostname.startsWith('www.')) {
  location.replace(
    `https://www.${location.hostname}${location.pathname}${location.search}`
  )
}

ApiService.init(API_URL.v1, API_URL.v2)

if (import.meta.env.VUE_MOCK_API) {
  // This import must be after initializing the ApiService to correctly generate the URLs
  const mockApi = import('@/mocks/browser')
  mockApi.then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  })
}

/*
 *  If token exists set header and mount interceptor
 */
if (TokenService.getToken()) {
  ApiService.setHeader()
  ApiService.mount401Interceptor()
}

/**
 * Smooth Scroll polyfill
 */
import smoothscroll from 'smoothscroll-polyfill'
// kick off the polyfill!
smoothscroll.polyfill()

const pinia = createPinia()

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(breakpoints)

app.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  component: 'Tippy', // => <Tippy/>
  componentSingleton: 'TippySingleton', // => <TippySingleton/>,
  defaultProps: vueTipppyDefaultProps,
})

if (!isLocalhost) {
  // on localhost is not required HOTJAR
  app.use(VueHotjar, {
    id: parseInt(import.meta.env.VUE_APP_HOTJAR_ID),
    isProduction: true,
  })
}

Sentry.init({
  app,
  dsn: import.meta.env.VUE_APP_SENTRY_URL,
  release: releaseVersion(),
  integrations: [Sentry.dedupeIntegration()],
  environment: import.meta.env.MODE,
  denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
  beforeSend(event, hint) {
    if (isCancel(hint?.originalException)) {
      console.debug(
        'Cancelled request ignored by Sentry',
        hint.originalException
      )
      return null
    }
    return event
  },
})

app.config.globalProperties.$bus = useEventBus()
app.mount('#app')

const testMode = useTestMode()
function changeTestModeState(state: boolean) {
  testMode.forceState(state)
  if (navigator.userAgent.includes('Firefox')) {
    // JS slows down in firefox when you type something into the console
    window.location.reload()
  }
}
window.disableTestMode = () => changeTestModeState(false)
window.enableTestMode = () => changeTestModeState(true)
