import ApiService from '@/services/api.service'
import type { AxiosResponse } from 'axios'
import type {
  LatestVersionResponse,
  ReleaseNotesResponse,
} from '@/types/services/system'
import type { EmptyDataResponse } from '@/types/services/common'

export class SystemService {
  static getLatestVersion(): Promise<AxiosResponse<LatestVersionResponse>> {
    return ApiService.get('/version.json')
  }

  static getReleaseNotes(
    until_tag?: string | null
  ): Promise<AxiosResponse<ReleaseNotesResponse>> {
    let url = '/api/v1/release_notes'
    if (until_tag) {
      url += `?until_tag=${until_tag}`
    }
    return ApiService.get(url)
  }

  static postBugReport(
    name: string,
    description: string,
    send_tg_info = true
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.post('/api/v1/bug_reporting', {
      name,
      description,
      send_tg_info,
    })
  }
}
