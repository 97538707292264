<template>
  <div class="mb-4" v-bind="$attrs">
    {{ $t('ui.bug_reporting.network_errors') }}
  </div>
  <div
    v-if="!errors.length"
    class="bg-background rounded px-2 py-1 text-secondary text-center text-sm"
  >
    {{ $t('ui.bug_reporting.no_errors') }}
  </div>
  <div v-else class="text-sm">
    <RequestErrorView
      v-for="(error, idx) in errors"
      :key="idx"
      :error="error"
      :class="{ 'mt-2': idx > 0 }"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { RequestError } from './bug_report'
import RequestErrorView from './RequestErrorView.vue'

defineProps({
  errors: {
    type: Array as PropType<RequestError[]>,
    default: () => [],
  },
})
</script>
