<template>
  <div class="sm:flex justify-between my-2">
    <div class="text-secondary mr-2">
      {{ label }}
    </div>
    <div
      v-tippy="value ? { delay: [500, 0], content: value } : undefined"
      class="sm:text-right truncate font-mono"
    >
      <slot v-if="$slots.default" />
      <template v-else>{{ value }}</template>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
})
</script>
