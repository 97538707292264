import ApiService from '@/services/api.service'
import { currentDateInUTCString } from '@/services/date.service'

import { TagType } from '@/constants/tags'

import type { AxiosResponse } from 'axios'
import type {
  AccountType,
  Asset,
  AssetBalanceRequest,
  AssetBalanceResponse,
  AssetHistoryBalanceResponse,
  AssetHistoryBalancesParams,
  AssetHistoryStatsParams,
  AssetHistoryStatsResponse,
  AssetInfoParams,
  AssetInfoPostParams,
  AssetInfoPostResponse,
  AssetInfoResponse,
  AssetNotificationParams,
  AssetNotificationResponse,
  AssetNotificationsParams,
  AssetNotificationsResponse,
  AssetsTableStreamMessage,
  AssetSourcesAsset,
  BlockchainData,
  BlockchainResponse,
  BlockchainsResponse,
  BlockchainsTableParams,
  BlockchainsTableResponse,
  CachedAssetsTableParams,
  CGCategoriesAsset,
  ClientAssetsTableParams,
  ClientAssetsTableResponse,
  CryptoAssetData,
  CryptoAssetResponse,
  CustomAssetPriceParams,
  CustomEntriesResponse,
  CustomEntriesTableParams,
  CustomEntryData,
  CustomEntryResponse,
  DashboardAsset,
  DetailTableParams,
  DetailTableResponse,
  ExchangeData,
  ExchangeResponse,
  ExchangesResponse,
  ExchangesTableParams,
  ExchangesTableResponse,
  ManualEntriesTableParams,
  ManualEntriesTableResponse,
  SupportedReturnType,
  UpdateCryptoAssetData,
  UpdateCustomAssetPriceParams,
  UpdateCustomEntryData,
  UpdateBlockchainData,
} from '@/types/services/assets'
import type { EmptyDataResponse } from '@/types/services/common'

class AssetsService {
  static getAssetsCached(
    params: CachedAssetsTableParams & { return_type: 'portfolio' },
    onMessage: (data: AssetsTableStreamMessage<Asset>) => void,
    onClose?: () => void
  ): Promise<void>

  static getAssetsCached(
    params: CachedAssetsTableParams & { return_type: 'category_stats' },
    onMessage: (data: AssetsTableStreamMessage<CGCategoriesAsset>) => void,
    onClose?: () => void
  ): Promise<void>

  static getAssetsCached(
    params: CachedAssetsTableParams & { return_type: 'dashboard' },
    onMessage: (data: AssetsTableStreamMessage<DashboardAsset>) => void,
    onClose?: () => void
  ): Promise<void>

  static getAssetsCached(
    params: CachedAssetsTableParams & { return_type: 'asset_sources' },
    onMessage: (data: AssetsTableStreamMessage<AssetSourcesAsset>) => void,
    onClose?: () => void
  ): Promise<void>

  static getAssetsCached(
    params: CachedAssetsTableParams & { return_type: SupportedReturnType },
    onMessage: (data: AssetsTableStreamMessage<any>) => void,
    onClose?: () => void
  ): Promise<void> {
    return ApiService.getStream(
      '/api/v1/account/stream_assets_cached',
      'POST',
      (message) => onMessage(JSON.parse(message.data)),
      onClose,
      params
    )
  }

  static getClientAssets(
    params: ClientAssetsTableParams
  ): Promise<AxiosResponse<ClientAssetsTableResponse>> {
    return ApiService.post('/api/v1/account/search/get_client_assets_faster', {
      aggr: {
        params,
      },
    })
  }

  static getExchangesData(
    params: ExchangesTableParams
  ): Promise<AxiosResponse<ExchangesTableResponse>> {
    return ApiService.post('/api/v1/account/search/exchanges_table', {
      aggr: { params },
    })
  }

  static getBlockchainsData(
    params: BlockchainsTableParams
  ): Promise<AxiosResponse<BlockchainsTableResponse>> {
    return ApiService.post('/api/v1/account/search/blockchains_table', {
      aggr: { params },
    })
  }

  static getManualEntryData(
    params: ManualEntriesTableParams
  ): Promise<AxiosResponse<ManualEntriesTableResponse>> {
    return ApiService.post('/api/v1/account/search/manual_entries_table', {
      aggr: { params },
    })
  }

  static getCustomEntriesData(
    params: CustomEntriesTableParams
  ): Promise<AxiosResponse<CustomEntriesResponse>> {
    return ApiService.post('/api/v1/account/search/icos_table', {
      aggr: { params },
    })
  }

  // NOT USED
  static getBlockchainsWallets(
    entity_id: string
  ): Promise<AxiosResponse<BlockchainsResponse>> {
    const body = {
      filters: [
        {
          column: 'type',
          relations: 'eq',
          value: 'blockchain',
        },
        {
          column: 'entity_id',
          relations: 'eq',
          value: entity_id,
        },
      ],
    }
    return ApiService.post(`/api/v1/account/search`, body)
  }

  /**
   * Get detail table of balances for certain account, used in Exchanges
   * or Blockchains tables
   */
  static getDetailTable(
    params: DetailTableParams
  ): Promise<AxiosResponse<DetailTableResponse>> {
    return ApiService.post(`/api/v1/account/search/get_account_balances`, {
      aggr: {
        params,
      },
    })
  }

  static getExchanges(
    entity_id: string
  ): Promise<AxiosResponse<ExchangesResponse>> {
    return ApiService.post(`/api/v1/account/search`, {
      filters: [
        {
          column: 'entity_id',
          relations: 'eq',
          value: entity_id,
        },
        {
          column: 'type',
          relations: 'eq',
          value: 'exchange',
        },
      ],
    })
  }

  // NOT USED
  static createEntry(
    account_id: string,
    type: AccountType,
    data: ExchangeData | BlockchainData | CryptoAssetData | CustomEntryData
  ): Promise<
    AxiosResponse<
      | ExchangeResponse
      | BlockchainResponse
      | CryptoAssetResponse
      | CustomEntryResponse
    >
  > {
    let typeUrl: string | null = null
    switch (type) {
      case 'manual_entry':
        typeUrl = 'manual_entries'
        break
      case 'ico':
        typeUrl = 'icos'
        break
      case 'exchange':
        typeUrl = 'exchanges'
        break
      case 'blockchain':
        typeUrl = 'blockchains'
        break
    }

    return ApiService.post(`/api/v1/accounts/${account_id}/${typeUrl}`, {
      ...data,
      request_timestamp: currentDateInUTCString(),
    })
  }

  static updateEntry(
    account_id: string,
    entry_id: string,
    type: 'exchange',
    data: ExchangeData
  ): Promise<AxiosResponse<ExchangeResponse>>

  static updateEntry(
    account_id: string,
    entry_id: string,
    type: 'blockchain',
    data: UpdateBlockchainData
  ): Promise<AxiosResponse<BlockchainResponse>>

  static updateEntry(
    account_id: string,
    entry_id: string,
    type: 'manual_entry',
    data: UpdateCryptoAssetData
  ): Promise<AxiosResponse<CryptoAssetResponse>>

  static updateEntry(
    account_id: string,
    entry_id: string,
    type: 'ico',
    data: UpdateCustomEntryData
  ): Promise<AxiosResponse<CustomEntryResponse>>

  static updateEntry(
    account_id: string,
    entry_id: string,
    type: AccountType,
    data:
      | ExchangeData
      | BlockchainData
      | UpdateCryptoAssetData
      | UpdateCustomEntryData
  ): Promise<
    AxiosResponse<
      | ExchangeResponse
      | BlockchainResponse
      | CryptoAssetResponse
      | CustomEntryResponse
    >
  > {
    // Create an account
    let typeUrl: string | null = null
    switch (type) {
      case 'manual_entry':
        typeUrl = 'manual_entries'
        break
      case 'ico':
        typeUrl = 'icos'
        break
      case 'exchange':
        typeUrl = 'exchanges'
        break
      case 'blockchain':
        typeUrl = 'blockchains'
        break
    }

    return ApiService.put(
      `/api/v1/accounts/${account_id}/${typeUrl}/${entry_id}`,
      data
    )
  }

  static getAssetBalance(
    params: AssetBalanceRequest
  ): Promise<AxiosResponse<AssetBalanceResponse>> {
    return ApiService.post('/api/v1/account/search/get_asset_balance', {
      aggr: {
        params,
      },
    })
  }

  static getAssetHistoryStats(
    params: AssetHistoryStatsParams,
    type: 'crypto' | 'fiat'
  ): Promise<AxiosResponse<AssetHistoryStatsResponse | EmptyDataResponse>> {
    return ApiService.post(
      `/api/v1/account/search/get_asset_history_stats?type=${type}`,
      {
        aggr: {
          params,
        },
      },
      false,
      true
    )
  }

  static getAssetHistoryBalances(
    params: AssetHistoryBalancesParams,
    allowConcurrentRequests = false
  ): Promise<AxiosResponse<AssetHistoryBalanceResponse>> {
    return ApiService.post(
      '/api/v1/account/search/get_asset_history_balances',
      {
        aggr: {
          params,
        },
      },
      allowConcurrentRequests
    )
  }

  static getAssetInfo(
    params: AssetInfoParams
  ): Promise<AxiosResponse<AssetInfoResponse>> {
    let queryString = `entity_id=${params.entity_id}`
    if (params.currency_id) {
      queryString += `&currency_id=${params.currency_id}`
    } else if (params.ico_id) {
      queryString += `&ico_id=${params.ico_id}`
    }
    return ApiService.get(`/api/v1/accounts/asset_info?${queryString}`)
  }

  static setAssetInfo(
    params: AssetInfoPostParams
  ): Promise<AxiosResponse<AssetInfoPostResponse>> {
    const tags = params.category_tags.map((tag) => ({
      tag_id: tag.id,
      tag_name: tag.name,
      type: TagType.CATEGORY_TAG,
    }))

    return ApiService.post('/api/v1/accounts/asset_info', {
      ...params,
      category_tags: tags,
      request_timestamp: currentDateInUTCString(),
    })
  }

  static getAssetNotifications(
    params: AssetNotificationsParams
  ): Promise<AxiosResponse<AssetNotificationsResponse>> {
    let column = ''
    let value = ''

    if (params.currency_id) {
      column = 'currency_id'
      value = params.currency_id
    } else if (params.ico_id) {
      column = 'ico_id'
      value = params.ico_id
    } else {
      throw Error('Invalid AssetNotificationsParams')
    }

    return ApiService.post('/api/v1/asset_notification/search', {
      filters: [
        { column, relations: 'eq', value },
        { column: 'entity_id', relations: 'eq', value: params.entity_id },
      ],
    })
  }

  static createAssetNotification(
    params: AssetNotificationParams
  ): Promise<AxiosResponse<AssetNotificationResponse>> {
    return ApiService.post('/api/v1/asset_notifications', {
      ...params,
      request_timestamp: currentDateInUTCString(),
    })
  }

  static updateAssetNotification(
    id: string,
    params: AssetNotificationParams
  ): Promise<AxiosResponse<AssetNotificationResponse>> {
    return ApiService.put(`/api/v1/asset_notifications/${id}`, params)
  }

  static removeAssetNotification(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/api/v1/asset_notifications/${id}`)
  }

  static createCustomAssetPrice(
    params: CustomAssetPriceParams
  ): Promise<AxiosResponse> {
    return ApiService.post('/api/v1/accounts/asset_custom_settings', params)
  }

  static updateCustomAssetPrice(
    params: UpdateCustomAssetPriceParams
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    const { id, ...data } = params
    return ApiService.put(`/api/v1/accounts/asset_custom_settings/${id}`, data)
  }

  static removeCustomAssetPrice(
    id: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.delete(`/api/v1/accounts/asset_custom_settings/${id}`)
  }

  static sendRequestForSupport(
    message: string,
    type: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.post('/api/v2/assets/support_request', {
      type,
      message,
    })
  }
}

export default AssetsService
