import type { AxiosResponse } from 'axios'

import ApiService from '@/services/api.service'
import { currentDateInUTCString } from '@/services/date.service'

import type {
  AccountData,
  AllAccountsResponse,
  AssetTypesResponse,
  BlockchainBalance,
  CheckBlockchainData,
  CheckBlockchainResponse,
  CheckExchangeData,
  CheckExchangeResponse,
  CreateAccountAndBlockchainResponse,
  CreateAccountAndCryptoAssetResponse,
  CreateAccountAndCustomEntryResponse,
  CreateAccountAndExchangeResponse,
  DetailedAccountsResponse,
  UpdateAccountResponse,
} from '@/types/services/account'
import type {
  AccountType,
  BlockchainData,
  CryptoAssetData,
  CustomEntryData,
  ExchangeData,
} from '@/types/services/assets'
import type { EmptyDataResponse } from '@/types/services/common'

class AccountService {
  static getAllAccounts(): Promise<AxiosResponse<AllAccountsResponse>> {
    return ApiService.get('/api/v1/accounts')
  }

  static createAccountAndEntry(
    accountData: AccountData & {
      create_date?: string | null
      type: 'blockchain'
    },
    entry: BlockchainData,
    balances: BlockchainBalance[]
  ): Promise<AxiosResponse<CreateAccountAndBlockchainResponse>>

  static createAccountAndEntry(
    accountData: AccountData & {
      create_date?: string | null
      type: 'exchange'
    },
    entry: ExchangeData
  ): Promise<AxiosResponse<CreateAccountAndExchangeResponse>>

  static createAccountAndEntry(
    accountData: AccountData & {
      create_date?: string | null
      type: 'manual_entry'
    },
    entry: CryptoAssetData
  ): Promise<AxiosResponse<CreateAccountAndCryptoAssetResponse>>

  static createAccountAndEntry(
    accountData: AccountData & { create_date?: string | null; type: 'ico' },
    entry: CustomEntryData
  ): Promise<AxiosResponse<CreateAccountAndCustomEntryResponse>>

  static createAccountAndEntry(
    accountData: AccountData & {
      create_date?: string | null
      type: AccountType
    },
    entry: BlockchainData | ExchangeData | CryptoAssetData | CustomEntryData,
    balances?: BlockchainBalance[]
  ): Promise<
    AxiosResponse<
      | CreateAccountAndBlockchainResponse
      | CreateAccountAndExchangeResponse
      | CreateAccountAndCryptoAssetResponse
      | CreateAccountAndCustomEntryResponse
    >
  > {
    let typeUrl = ''
    switch (accountData.type) {
      case 'manual_entry':
        typeUrl = 'manual_entries'
        break
      case 'ico':
        typeUrl = 'icos'
        break
      case 'exchange':
        typeUrl = 'exchanges'
        break
      case 'blockchain':
        typeUrl = 'blockchains'
        break
      // no default
    }

    return ApiService.post(`/api/v1/accounts_full/${typeUrl}`, {
      account: accountData,
      balances,
      child: entry,
      request_timestamp: currentDateInUTCString(),
    })
  }

  static getDetailedAccounts(
    entity_id: string
  ): Promise<AxiosResponse<DetailedAccountsResponse>> {
    return ApiService.post('/api/v1/account/search/get_detailed_accounts', {
      aggr: {
        params: {
          entity_id,
        },
      },
    })
  }

  // NOT USED
  static createAccount(
    data: AccountData & { create_date?: string | null }
  ): Promise<AxiosResponse<any>> {
    return ApiService.post(`/api/v1/accounts`, {
      ...data,
      request_timestamp: currentDateInUTCString(),
    })
  }

  static updateAccount(
    account_id: string,
    data: AccountData
  ): Promise<AxiosResponse<UpdateAccountResponse>> {
    return ApiService.put(`/api/v1/accounts/${account_id}`, data)
  }

  /**
   * Delete account and related asset entry
   */
  static deleteAccount(
    account_id: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.delete(`/api/v1/accounts/${account_id}`)
  }

  /**
   * Check if blockchain data are valid.
   */
  static checkBlockchain(
    data: CheckBlockchainData
  ): Promise<AxiosResponse<CheckBlockchainResponse>> {
    return ApiService.post(`/api/v1/check/accounts/blockchain`, data)
  }

  /**
   * Check if exchange data are valid.
   */
  static checkExchange(
    data: CheckExchangeData
  ): Promise<AxiosResponse<CheckExchangeResponse>> {
    return ApiService.post(`/api/v1/check/accounts/exchange`, data)
  }

  /**
   * Fetch and save balance for saved exchange account.
   * @param exchange_id Exchange UUID
   */
  static fetchBalance(
    exchange_id: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.post('/api/v1/fetch_balance/accounts/exchange', {
      exchange_id,
    })
  }

  static getAssetTypes(): Promise<AxiosResponse<AssetTypesResponse>> {
    return ApiService.post('/api/v1/account_sources/search/get_asset_types', {
      aggr: { params: {} },
    })
  }
}

export default AccountService
