import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.914 10.517v3.796m4.616-1.85A6.355 6.355 0 0 1 1.638 8.073 6.3 6.3 0 0 1 3.513 3.51m4.419-1.852a6.355 6.355 0 0 1 6.416 6.293m-8.104 1.73L3.52 12.402m4.417-7.169V2.396m2.582 5.59h3.62M9.826 9.73l2.638 2.637M4.3 4.357l1.456 1.587m4.63 2.047a2.393 2.393 0 0 1-2.394 2.393A2.393 2.393 0 0 1 5.6 7.991 2.393 2.393 0 0 1 7.99 5.6a2.393 2.393 0 0 1 2.393 2.392" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }