import type { Currency } from '@/types/services/global'
import type { CurrencyArray } from '@/types/services/currencies'
import fuzzySearch from '@/helpers/fuzzySearch'
import BigNumber from 'bignumber.js'

/**
 * Formats a currency object for full text search, highlighting the name and symbol if applicable.
 *
 * @param {Currency} currency - The currency object to format.
 * @returns {string} The formatted currency string.
 */
export function formatFullTextSearch(currency: Currency): string {
  let name = currency.name
  let symbol = currency.symbol
  if (currency.highlight) {
    // Can not split text prop by '-' to decompose highlight to name and symbol. Some currencies has '-' in name or symbol.
    if (!currency.highlight.name && !currency.highlight.symbol) {
      name = `<b>${name}</b>`
      symbol = `<b>${symbol}</b>`
    } else {
      name = currency.highlight.name || currency.name
      symbol = currency.highlight.symbol || currency.symbol
    }
  }
  return `${name} <small>${symbol}</small>`
}

/**
 * Converts a sorted array from currency.json to a Currency object.
 *
 * @param {CurrencyArray} arr - The CurrencyArray to convert.
 * @param {string} group - Group name.
 * @returns {Currency} The converted Currency object.
 */
export function convertCurrencyArray(
  arr: CurrencyArray,
  group: string
): Currency {
  return {
    id: arr[0],
    source: arr[1],
    source_id: arr[2],
    name: arr[3],
    symbol: arr[4],
    has_image: arr[5],
    last_mcap: arr[6],
    contract_address: arr[7],
    platform: arr[8],
    fdv_usd: arr[9],
    group,
    text: `${arr[3]} - ${arr[4]}`,
  }
}

/**
 * Filters an array of currencies based on a search string.
 *
 * @param {Currency[]} currencies - The array of currencies to filter.
 * @param {string} search - The search string to filter by.
 * @returns {Currency[]} The filtered array of currencies.
 */
export function searchCurrencies(
  currencies: Currency[],
  search: string
): Currency[] {
  let options = currencies.slice()
  if (search.length > 0) {
    options = fuzzySearch(search, options, {
      keys: ['symbol', 'name', 'text'],
      // prioritize symbol key
      scoreFn: (a) => Math.max(a[0].score * 1.25, a[1].score, a[2].score),
    })
  } else {
    // delete old search highlighted results
    options.forEach((option) => delete option.highlight)
  }
  return options.sort((a: Currency, b: Currency) => {
    if (
      search.length &&
      a.group != b.group &&
      (a.group == 'fiat' || b.group == 'fiat')
    ) {
      return a.group == 'fiat' ? -1 : 1
    }
    if (a.last_mcap == b.last_mcap) {
      // check search length for old searchScore
      if (a.searchScore == b.searchScore || search.length == 0) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      }
      return (b.searchScore ?? 0) - (a.searchScore ?? 0)
    }
    return new BigNumber(b.last_mcap ?? 0)
      .minus(new BigNumber(a.last_mcap ?? 0))
      .toNumber()
  })
}
