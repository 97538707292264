import { keyBy } from 'lodash-es'
import { isCurrencyValueArray } from '@/helpers/objects'
import { useUserStore } from '@/stores/user'
import type { CurrencyValue } from '@/types/services/common'
import type { TargetCurrency } from '@/types/services/assets'

export type CurrenciesValues = {
  fiat: number | null
  crypto: number | null
}

export type TargetCurrencyValues = {
  fiat: TargetCurrency | null
  crypto: TargetCurrency | null
}

export function useValues() {
  const userStore = useUserStore()

  function findValueByCurrencyId(
    values: CurrencyValue[],
    currencyId: string
  ): number | null {
    return values.find((v) => v.currency_id === currencyId)?.value ?? null
  }

  function findFiatValue(values: CurrencyValue[]) {
    if (!userStore.currencyFiat) {
      return null
    }
    return findValueByCurrencyId(values, userStore.currencyFiat.id)
  }

  function findCryptoValue(values: CurrencyValue[]) {
    if (!userStore.currencyCrypto) {
      return null
    }
    return findValueByCurrencyId(values, userStore.currencyCrypto.id)
  }

  function findCurrenciesValues(values: CurrencyValue[]) {
    const valuesMap = keyBy(values, 'currency_id')

    const foundValues: CurrenciesValues = {
      fiat: null,
      crypto: null,
    }

    if (userStore.currencyFiat) {
      foundValues.fiat = valuesMap[userStore.currencyFiat.id]?.value ?? null
    }

    if (userStore.currencyCrypto) {
      foundValues.crypto = valuesMap[userStore.currencyCrypto.id]?.value ?? null
    }

    return foundValues
  }

  function findTargetCurrencies(tc: TargetCurrency[]) {
    const tcMap = keyBy(tc, 'currency_id')

    const foundValues: TargetCurrencyValues = {
      fiat: null,
      crypto: null,
    }

    if (userStore.currencyFiat) {
      foundValues.fiat = tcMap[userStore.currencyFiat.id] ?? null
    }

    if (userStore.currencyCrypto) {
      foundValues.crypto = tcMap[userStore.currencyCrypto.id] ?? null
    }

    return foundValues
  }

  function countObjectPropertySum<T>(
    objects: T[],
    property: keyof T,
    type: 'fiat' | 'crypto'
  ) {
    const findFn = type === 'fiat' ? findFiatValue : findCryptoValue
    let sum = 0

    for (const obj of objects) {
      const value = isCurrencyValueArray(obj[property])
        ? (findFn(obj[property]) ?? 0)
        : 0
      sum += value
    }

    return sum
  }

  return {
    findValueByCurrencyId,
    findFiatValue,
    findCryptoValue,
    findCurrenciesValues,
    findTargetCurrencies,
    countObjectPropertySum,
  }
}
