import i18n from '../langs'
import type { Tag } from '@/types/services/tags'

export const TagType = {
  CLASSIFICATION: 'classification',
  TAG: 'tag',
  TYPE: 'type',
  CATEGORY_TAG: 'category_tag',
} as const

export const NO_CATEGORY_TAG_ID = '_no_category_'

export const NO_CATEGORY_TAG: Tag = {
  id: NO_CATEGORY_TAG_ID,
  name: i18n.global.t('ui.common.no_category'),
  root_id: '',
  type: 'category_tag',
}
