<template>
  <div>
    <div class="mb-4">{{ category.label }}</div>
    <div class="bg-background rounded px-2 py-1 text-sm">
      <LabelValue
        v-for="(item, idx) in category.items"
        :key="idx"
        :label="item.label"
        :value="item.value"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import LabelValue from '@/components/misc/LabelValue'
import type { PropType } from 'vue'
import type { BasicInfoCategory } from './bug_report'

defineProps({
  category: {
    type: Object as PropType<BasicInfoCategory>,
    required: true,
  },
})
</script>
