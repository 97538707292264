<template>
  <Error503 v-if="globalStore.showMaintenance" />
  <router-view v-else-if="showPlatform && !isLoggingOut" />
  <div v-else class="crypkit-modal-loading">
    <div class="text-center">
      <Spinner width="100" class="fill-brand" />
      {{ isLoggingOut ? $t('ui.common.signing_out') : $t('ui.common.loading') }}
    </div>
  </div>

  <SnackBar />

  <BugReporting />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import Spinner from '@/assets/loaders/Spinner.svg?component'
import SnackBar from '@/components/misc/SnackBar'
import Error503 from '@/layouts/503.vue'

import { useBroadcast } from '@/composables/broadcast'
import { useTestMode } from '@/composables/test_mode'
import { EVENT_LOGGED_OUT, EVENT_SESSION_CHANGED } from '@/constants/broadcast'
import { retrieveErrorsMessages } from '@/helpers/errors'
import serviceWorker from '@/mixins/serviceWorker'
import { setupRoutes } from '@/router'
import ApiService from '@/services/api.service'
import UserService from '@/services/user/user'
import { useGlobalStore } from '@/stores/global'
import { useSettingsGeneralStore } from '@/stores/settings-general'
import { useUserStore } from '@/stores/user'

import type { Entity, Root } from '@/types/services/root'
import type { ClientEntity } from '@/types/services/user'
import type { Currency } from '@/types/services/global'
import { BugReporting } from '@/components/misc/BugReporting'

export default defineComponent({
  components: {
    BugReporting,
    Error503,
    Spinner,
    SnackBar,
  },

  mixins: [serviceWorker],

  data() {
    return {
      showPlatform: false,
      lastActive: Date.now(),
      sessionInterval: null as ReturnType<typeof setInterval> | null,
      refreshInterval: null as ReturnType<typeof setInterval> | null,

      globalStore: useGlobalStore(),
      settingsGeneralStore: useSettingsGeneralStore(),
      userStore: useUserStore(),

      broadcast: useBroadcast(),
      testMode: useTestMode(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['allCurrencies']),
    ...mapState(useUserStore, [
      'sessionStarted',
      'isLoggingOut',
      'root',
      'packages',
      'currencyCrypto',
      'currencyFiat',
      'currencyHash',
      'entities',
      'entity',
      'clientEntity',
      'hasPackage',
      'sessionTimeout',
    ]),
    ...mapState(useUserStore, {
      theme: (store) => store.settings.additional.theme,
    }),
    anyPackages(): boolean {
      return this.packages.length > 0
    },
  },

  watch: {
    theme: {
      immediate: true,
      handler(newTheme: string, oldTheme: string) {
        const el = document.body
        el.classList.remove(`theme-${oldTheme}`)
        el.classList.add(`theme-${newTheme}`)
      },
    },
    sessionStarted() {
      this.prepareAppPlatform()
    },
    root: {
      handler(next: Root, prev: Root) {
        if (JSON.stringify(next) !== JSON.stringify(prev)) {
          this.$nextTick(() => {
            this.$bus.emit('rootChanged')
          })
        }
      },
    },
    entity: {
      immediate: true,
      handler(next: Entity | null, prev: Entity | null) {
        this.handlerEntityChanged(next, prev)
      },
    },
    clientEntity: {
      immediate: true,
      handler(next: ClientEntity | null, prev: ClientEntity | null) {
        this.handlerEntityChanged(next, prev)
      },
    },
    currencyHash: {
      handler(next: string | null, prev: string | null) {
        if (next && next !== prev) {
          this.getCurrencyRatios()
          this.$nextTick(() => {
            this.$bus.emit('selectedCurrenciesChanged')
          })
        }
      },
    },
    currencyFiat: {
      handler(next: Currency | null, prev: Currency | null) {
        if (next && JSON.stringify(next) !== JSON.stringify(prev)) {
          this.$nextTick(() => {
            this.$bus.emit('selectedFiatChanged')
          })
        }
      },
    },
    currencyCrypto: {
      handler(next: Currency | null, prev: Currency | null) {
        if (next && JSON.stringify(next) !== JSON.stringify(prev)) {
          this.$nextTick(() => {
            this.$bus.emit('selectedCryptoChanged')
          })
        }
      },
    },
    allCurrencies: {
      handler(next, prev) {
        if (next && next !== prev) {
          this.checkClientDefaultCurrencies()
          this.$nextTick(() => {
            this.$bus.emit('currenciesListChanged')
          })
        }
      },
    },
    entities: {
      handler(next, prev) {
        if (next && next !== prev) {
          this.$nextTick(() => {
            this.$bus.emit('entitiesListChanged')
          })
        }
      },
    },
  },

  created() {
    this.sessionInterval = setInterval(this.checkSessionTimeout, 2000)
    this.refreshInterval = setInterval(this.getCurrencyRatios, 10 * 60 * 1000)

    this.prepareAppPlatform()

    this.$bus.on('showMaintenance', this.showMaintenance)

    window.addEventListener('visibilitychange', this.setLastVisitedEntity)

    this.broadcast.on(EVENT_SESSION_CHANGED, this.handleUserSessionChanged)
    this.broadcast.on(EVENT_LOGGED_OUT, this.handleUserSessionChanged)
  },

  beforeUnmount() {
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval)
    }
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }

    this.$bus.off('showMaintenance', this.showMaintenance)

    window.removeEventListener('visibilitychange', this.setLastVisitedEntity)

    this.broadcast.off(EVENT_SESSION_CHANGED, this.handleUserSessionChanged)
    this.broadcast.off(EVENT_LOGGED_OUT, this.handleUserSessionChanged)
  },

  methods: {
    checkSessionTimeout() {
      if (!this.sessionStarted) {
        return
      }

      const sessionTimeout = this.sessionTimeout.value
      const isWindowActive = !document.hidden
      if (isWindowActive) {
        // Reset timeout
        this.lastActive = Date.now()
      } else {
        // Log out if inactive for longer than session timeout
        const now = Date.now()
        const shouldLogout = now - this.lastActive > sessionTimeout
        if (shouldLogout) {
          this.userStore.expireSessions()
        }
      }
    },
    async checkClientDefaultCurrencies() {
      if (this.userStore.currentView == 'client' && this.allCurrencies.length) {
        await this.userStore.getDefaultCurrencies()
      }
    },
    async prepareAppPlatform() {
      await ApiService.liveCheck()

      if (this.sessionStarted) {
        this.showPlatform = false
        try {
          await this.loadMe(true)

          this.showPlatform = true

          await this.getAdditionalData()
        } catch (err) {
          this.$bus.emit('error', err)
        }
      } else {
        this.showPlatform = true
      }
    },
    async loadMe(withRelations = false) {
      const promisesRequired: Promise<void>[] = []

      if (withRelations) {
        promisesRequired.push(this.userStore.getRelations())
      }
      promisesRequired.push(this.userStore.getMe())
      promisesRequired.push(this.globalStore.getGeneral())

      try {
        await Promise.all(promisesRequired)
        // This sets the routes for either normal platform
        // or client fund depending on user role
        setupRoutes()
        await this.$router.replace(this.$route.fullPath)
      } catch (err) {
        UserService.saveAuthErrors(retrieveErrorsMessages(err))
        console.warn('Required data fetching failed')
        if (!this.globalStore.showMaintenance) {
          UserService.logout()
        }
      }
    },
    async getAdditionalData() {
      const promises: Promise<void>[] = []

      promises.push(this.globalStore.getAllCurrencies())

      if (this.userStore.root) {
        // Account tags are dependent on root
        promises.push(this.settingsGeneralStore.getTags())
      }

      if (this.anyPackages) {
        promises.push(this.globalStore.getExchanges())
        promises.push(this.globalStore.getBlockchains())
      }

      try {
        await Promise.all(promises)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    async getCurrencyRatios() {
      if (!this.sessionStarted || this.packages.length === 0) {
        return
      }

      try {
        await this.globalStore.getCurrencyRatios()
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    handlerEntityChanged(
      next: Entity | ClientEntity | null,
      prev: Entity | ClientEntity | null
    ) {
      if (next) {
        this.checkClientDefaultCurrencies()

        if (next && !this.testMode.isStatusForced()) {
          const entityId = 'entity_id' in next ? next.entity_id : next.id
          this.testMode.toggleByEntity(entityId)
        }

        if (JSON.stringify(next) !== JSON.stringify(prev)) {
          if (prev?.valuation_method !== next.valuation_method) {
            this.getCurrencyRatios()
          }
          this.$nextTick(() => {
            this.$bus.emit('entityChanged')
          })
        }
      }
    },
    showMaintenance() {
      this.globalStore.showMaintenance = true
    },
    setLastVisitedEntity() {
      if (document.visibilityState == 'visible') {
        const entity = this.entity || this.clientEntity
        if (entity) {
          const entity_id = 'entity_id' in entity ? entity.entity_id : entity.id
          UserService.setLastVisibleEntityId(entity_id)
        }
      }
    },
    handleUserSessionChanged() {
      location.reload()
    },
  },
})
</script>
