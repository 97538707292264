import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "8",
      cy: "10",
      r: "4.25"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", { d: "M14.25 10.25h-1.5m-1 2.5 1.5 1.5m0-8.5-1.5 1.5m-10 3h1.5m1 2.5-1.5 1.5m0-8.5 1.5 1.5m1.5-1.5s-.75-3 2.25-3 2.25 3 2.25 3" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }