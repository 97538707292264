import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.273 2.583v10.788zM2.148 7.972h7.81zm3.568.285v5.098zm.231 2.663h4.092zM3.278 2.327h9.469c.722 0 1.307.548 1.307 1.224v8.835c0 .71-.614 1.287-1.374 1.287H3.308c-.753 0-1.363-.572-1.363-1.275V3.576c0-.69.597-1.249 1.333-1.249" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }