import axios from 'axios'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import { last } from 'lodash-es'
import { UAParser } from 'ua-parser-js'
import { friendlyEntityTypes } from '@/helpers/friendlyNames'
import { API_URL, downloadFile } from '@/helpers/system'
import apiService from '@/services/api.service'
import { useUserStore } from '@/stores/user'
import { dateToUTCString } from '@/services/date.service'
import { useSystemStore } from '@/stores/system'
import { retrieveErrorsMessages } from '@/helpers/errors'

export interface BasicInfo {
  label: string
  value: string
}

export interface BasicInfoCategory {
  label: string
  items: BasicInfo[]
}

export interface ConsoleError {
  date: Date
  message: string
  name: string
}

export interface RequestError {
  date: Date
  url?: string
  method?: string
  correlation_id?: string
  data: any
  response: any
  status?: number
  message: string
}

const consoleErrors = ref<ConsoleError[]>([])
const reqErrors = ref<RequestError[]>([])

const originalConsoleError = console.error
console.error = function (...args: any[]) {
  const error = args.find((arg) => arg instanceof Error)

  consoleErrors.value.push({
    date: new Date(),
    message: error?.stack ?? args.join(', '),
    name: error?.name ?? 'Error',
  })

  originalConsoleError.apply(console, args)
}

apiService.setErrorListener((error, correlation_id) => {
  // do not log cancelled requests
  if (axios.isCancel(error)) {
    return
  }

  // do not log unauthorized requests until they are retried after refresh token
  if (error.response?.status === 401 && !error.config?._retry) {
    return
  }

  reqErrors.value.push({
    date: new Date(),
    url: error.config?.url,
    method: error.config?.method?.toUpperCase(),
    correlation_id,
    data: error.config?.data,
    response: error.response?.data,
    status: error.response?.status,
    message: retrieveErrorsMessages(error).join(', '),
  })
})

export function useBugReport() {
  const { t } = useI18n()
  const userStore = useUserStore()
  const systemStore = useSystemStore()

  const errorMessages = ref<string[]>([])

  const hasError = computed(
    () => getConsoleErrors().length > 0 || getRequestErrors().length > 0
  )

  function getHostnameAndPort(): string {
    const { hostname, port } = window.location
    return port ? `${hostname}:${port}` : hostname
  }

  function getBasicInfo(): BasicInfoCategory[] {
    const { browser, os } = UAParser(navigator.userAgent)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const date = new Date()
    const timezoneOffset = date.getTimezoneOffset()
    const hoursOffset = (timezoneOffset / 60) * -1

    const systemTimezone = `${timeZone} (UTC${hoursOffset > 0 ? '+' : ''}${hoursOffset})`
    const entityTz = userStore.entityTimezone
    const tzOffset = entityTz ? parseFloat(entityTz.time_offset) : 0
    const productTimezone = entityTz
      ? `${entityTz.name} (UTC${tzOffset > 0 ? '+' : ''}${tzOffset})`
      : '-'

    return [
      {
        label: t('ui.bug_reporting.system_info'),
        items: [
          {
            label: t('ui.bug_reporting.location'),
            value: getHostnameAndPort(),
          },
          {
            label: t('ui.bug_reporting.api_url_version', { version: 'v1' }),
            value: API_URL.v1,
          },
          {
            label: t('ui.bug_reporting.api_url_version', { version: 'v2' }),
            value: API_URL.v2,
          },
        ],
      },
      {
        label: t('ui.bug_reporting.user_info'),
        items: [
          {
            label: t('ui.common.os'),
            value: `${os.name} ${os.version}`,
          },
          {
            label: t('ui.common.browser'),
            value: `${browser.name} ${browser.version}`,
          },
          {
            label: t('ui.common.date'),
            value: format(date, 'yyyy-MM-dd HH:mm:ss'),
          },
          {
            label: t('ui.common.timezone'),
            value: systemTimezone,
          },
        ],
      },
      {
        label: t('ui.bug_reporting.selected_product'),
        items: [
          {
            label: t('ui.common.name'),
            value: userStore.entity?.name || '-',
          },
          {
            label: 'UUID',
            value: userStore.entity?.id || '-',
          },
          {
            label: t('ui.common.type'),
            value: userStore.entity?.type
              ? friendlyEntityTypes[userStore.entity.type]
              : '-',
          },
          {
            label: t('ui.common.timezone'),
            value: productTimezone,
          },
        ],
      },
    ]
  }

  function getConsoleErrors(): ConsoleError[] {
    return consoleErrors.value
  }

  function getRequestErrors(): RequestError[] {
    return reqErrors.value
  }

  function getBasicInfoToTxt() {
    let txt = 'Basic info\n==========\n\n'

    for (const basicInfo of getBasicInfo()) {
      txt += `${basicInfo.label}\n`
      for (const item of basicInfo.items) {
        txt += `- ${item.label}: ${item.value}\n`
      }
      txt += '\n'
    }

    return txt
  }

  function getNetworkErrorsToTxt() {
    let txt = 'Network errors\n==============\n\n'

    for (const error of getRequestErrors()) {
      txt += `[${dateToUTCString(error.date)}] ${error.url}\n`
      txt += `- Correlation ID: ${error.correlation_id}\n`
      txt += `- Status: ${error.status}\n`
      txt += `- Method: ${error.method}\n`
      txt += `- Data: ${error.data}\n`
      txt += `- Response: ${JSON.stringify(error.response)}\n`
      txt += `- Message: ${error.message}\n\n`
    }

    return txt
  }

  function getConsoleErrorsToTxt() {
    let txt = 'Console errors\n==============\n\n'

    for (const error of getConsoleErrors()) {
      txt += `[${dateToUTCString(error.date)}] ${error.message}\n\n`
    }

    return txt
  }

  function getTxtContent() {
    let data = getBasicInfoToTxt()
    data += '\n\n'
    data += getNetworkErrorsToTxt()
    data += '\n\n'
    data += getConsoleErrorsToTxt()
    return data
  }

  function clearErrors() {
    reqErrors.value = []
    consoleErrors.value = []
  }

  function download() {
    const filename = `bug-report-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.txt`

    downloadFile(
      `data:text/plain;charset=utf-8,${encodeURI(getTxtContent())}`,
      filename
    )
  }

  async function send(customerNote = '') {
    if (!hasError.value) return true

    errorMessages.value = []

    const name =
      last(getRequestErrors())?.message ??
      last(getConsoleErrors())?.name ??
      'Unknown error'

    let txtContent = getTxtContent()
    if (customerNote) {
      customerNote = `Customer note\n============\n\n${customerNote}\n\n`
      txtContent = `${customerNote}\n\n${txtContent}`
    }

    try {
      await systemStore.sendBugReport(`[Bug Report] ${name}`, txtContent)
    } catch (error) {
      errorMessages.value = retrieveErrorsMessages(error)
      return false
    }
    return true
  }

  return {
    errorMessages,
    hasError,
    getBasicInfo,
    getConsoleErrors,
    getRequestErrors,
    clearErrors,
    download,
    send,
  }
}
