<template>
  <div id="account-selection">
    <div
      class="rounded border border-outline bg-surface h-control flex items-center transition-colors select-none hover:border-brand hover:cursor-pointer text-secondary"
      :class="sidebarToggled ? 'px-2.5 justify-between' : 'justify-center'"
      @click="handleClick"
    >
      <template v-if="sidebarToggled">
        <span class="font-medium text-base truncate mr-2">
          <Avatar :entity="avatarEntity" />
          {{ currentEntityName }}
        </span>
        <SvgIcon
          icon="ArrowDown"
          class="shrink-0 w-2.5 h-2.5 mr-0.5"
          :class="{ 'transform rotate-180 text-brand': show }"
        />
      </template>
      <Avatar v-else :entity="avatarEntity" class="mx-0" />
    </div>

    <Menu v-model="show" :max-width="218">
      <AccountExpandPanels
        :relations="relations"
        :current="currentRelation"
        dense
        @start-session="switchSession"
      />
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import Avatar from '@/components/misc/Avatar'
import Menu from '@/components/misc/Menu'
import SvgIcon from '@/components/misc/SvgIcon'
import AccountExpandPanels from './AccountExpandPanels.vue'

import type { AvatarEntity } from '@/types/components/avatar'
import type { UserRootRelation } from '@/types/services/user'
import type { StartSessionItem } from './EntityRolePanels.vue'

export default defineComponent({
  components: {
    AccountExpandPanels,
    Avatar,
    Menu,
    SvgIcon,
  },

  data() {
    return {
      show: false,

      globalStore: useGlobalStore(),
      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['sidebarToggled']),
    ...mapState(useUserStore, [
      'currentView',
      'clientEntity',
      'entity',
      'root',
      'relations',
      'user',
    ]),
    currentEntityName() {
      return this.entity?.name || this.clientEntity?.name
    },
    avatarEntity(): AvatarEntity {
      return {
        id: this.root.id,
        text: this.root.name,
      }
    },
    currentRelation(): UserRootRelation | null {
      return this.relations.find((rel) => rel.root_id == this.root.id) || null
    },
  },

  methods: {
    handleClick() {
      if (this.sidebarToggled) {
        this.show = !this.show
      } else {
        this.globalStore.toggleSidebar()
      }
    },
    async switchSession(item: StartSessionItem) {
      if (!this.user) {
        return
      }

      try {
        const targetView = item.role == 'superadmin' ? 'admin' : item.role
        const isSameView = targetView == this.currentView
        if (isSameView && item.root_id == this.root.id) {
          await this.userStore.changeEntity(item.entity_id)
        } else {
          this.userStore.isLoggingOut = true
          await this.userStore.startSession({
            user_id: this.user.id,
            root_id: item.root_id,
            entity_id: item.root_user_entity_id,
            role: item.role,
            selected_entity_id: item.entity_id,
          })

          if (isSameView) {
            window.location.reload()
          } else {
            const base = this.$router.options.history.base
            location.href = base != '' && base != '/' ? `/${base}/` : '/'
          }
        }
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
  },
})
</script>
