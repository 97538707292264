import { acceptHMRUpdate, defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'
import { addMinutes, format, subMinutes } from 'date-fns'

import { type TimeoutOption, useGlobalStore } from './global'

import { HttpError } from '@/helpers/errors'
import { getTextFromEntity } from '@/helpers/entity'
import { friendlyEntityTypes } from '@/helpers/friendlyNames'

import ApiService from '@/services/api.service'
import {
  dateToString,
  formatDateHelper,
  militaryGMTOffsetFromNumeric,
  stringToUTCDate,
} from '@/services/date.service'
import TokenService from '@/services/user/token'
import UserService from '@/services/user/user'
import UserSettingsService from '@/services/user/user_settings'

import type { FeeType } from '@/types/services/fund_product'
import type { Currency, Timezone } from '@/types/services/global'
import type {
  Entity,
  EntityType,
  Root,
  RootPackage,
} from '@/types/services/root'
import type {
  ClientEntity,
  MeRootUser,
  Permission,
  SessionRole,
  StartSessionParams,
  Token,
  User,
  UserRootRelation,
} from '@/types/services/user'
import type {
  DayOfWeek,
  TourFlow,
  UserSettings,
  UserSettingsWithRequiredAdditional,
} from '@/types/services/user_settings'
import type { RootUserRelation } from '@/types/services/root_user'
import type { Role } from '@/types/services/user'
import { themeValues } from '@/types/services/user_settings'

export interface PermissionMap {
  [key: string]: number[]
}

export interface EntitiesGroup {
  icon: string
  text: string
  entities: Entity[]
}

export type GroupedEntities = {
  [key in EntityType]?: EntitiesGroup
}

export interface UpdateProfileParams {
  first_name: string
  last_name: string
  email: string
  password: string | null
}

export type StartSessionWithSelectedEntityParams = StartSessionParams & {
  selected_entity_id?: string
}

export interface UserState {
  token: Token | null
  sessionStarted: boolean
  user: User | null
  currentView: SessionRole | null
  currentEntityId: string | null
  isLoggingOut: boolean
  owners: Entity[]
  fund_products: ClientEntity[]
  userLoading: boolean
  root_user: MeRootUser | null
  root: Root
  settings: UserSettingsWithRequiredAdditional
  fee_types: FeeType[]
  permissions: PermissionMap
  relations: UserRootRelation[]
  packages: number[]
  packages_full: RootPackage[]
}

export const useUserStore = defineStore('user', {
  state(): UserState {
    const token = TokenService.getToken()
    const currentEntityId =
      UserService.getFromUrlParamsEntityId() ||
      UserService.getCurrentSessionEntityId() ||
      UserService.getLastVisibleEntityId()

    return {
      token,
      sessionStarted: Boolean(token),
      user: null,
      currentView: null,
      currentEntityId,
      isLoggingOut: false,
      owners: [],
      fund_products: [],
      userLoading: true,
      root_user: null,
      root: {
        id: '',
        create_date: '',
        delete_date: null,
        is_new: false,
        name: 'null',
        color_primary: null,
        logo_full_url: null,
        logo_icon_url: null,
      },
      settings: {
        id: '',
        user_id: '',
        base_currency_id: '',
        base_crypto_id: '',
        confirmed: false,
        two_factor: 'none',
        two_factor_code: null,
        session_timeout: null,
        last_seen_new_defi_services: null,
        additional: {
          admins_view: 'table',
          profile_icon_color: '#2985E3',
          hide_small: 10,
          hide_small_bool: false,
          table_pagination: 10,
          date_format: 'dd/MM/yyyy',
          privacy_mode: false,
          theme: 'light',
          tour_flow: null,
          week_starts_on: 1,
          clients_show_deactivated: false,
          admins_show_deactivated: false,
          referrals_show_deactivated: false,
          dashboard_benchmark: 'bitcoin',
          default_session: null,
          last_read_release_notes_version: null,
        },
      },
      fee_types: [],
      permissions: {},
      relations: [],
      packages: [],
      packages_full: [],
    }
  },

  actions: {
    async login(email: string, password: string) {
      const hashedPassword = UserService.hashPassword(password, email)
      const lowerCasePassword = /[A-Z]/u.test(email)
        ? UserService.hashPassword(password, email.toLowerCase())
        : null

      const response = await UserService.login(
        email,
        hashedPassword,
        lowerCasePassword
      )

      this.$patch({
        token: response.data.token,
        user: response.data.user,
      })
    },

    async startSession(params: StartSessionWithSelectedEntityParams) {
      if (this.token == null) {
        throw Error('Token was not set')
      }

      const { selected_entity_id, ...start_session_params } = params

      try {
        await UserService.startSession(this.token, start_session_params)
        if (selected_entity_id) {
          await this.changeEntity(selected_entity_id)
        }
        this.sessionStarted = true
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async expireSessions() {
      await UserService.logout('?expired=true')
    },

    async registerUser({
      email,
      password,
      first_name,
      last_name,
    }: {
      email: string
      password: string
      first_name: string
      last_name: string
    }) {
      const lowerCaseEmail = email.toLowerCase()
      const hashedPassword = UserService.hashPassword(password, lowerCaseEmail)

      const data = {
        email: lowerCaseEmail,
        password: hashedPassword,
        first_name: first_name,
        last_name: last_name,
      }

      try {
        const response = await UserService.registerUser(data)
        this.token = response.data.token
        return response
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getRelations() {
      if (this.token == null) {
        throw Error('Token was not set')
      }

      try {
        const response = await UserService.getRelations(
          this.token.Authorization
        )
        this.relations = response.data.data
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getMe() {
      this.userLoading = true

      try {
        const response = await UserService.getMe()
        const data = response.data.data

        this.setUserSettings(data.user.settings)
        this.setPermissions(data.session.permissions, data.session.relations)
        this.$patch({
          user: data.user,
          root: data.session.root,
          owners: data.session.owners.map((owner: Entity) => ({
            ...owner,
            text: getTextFromEntity(owner),
          })),
          root_user: data.session.root_user,
          packages: data.session.packages,
          packages_full: data.session.packages_full,
          fee_types: 'fee_types' in data.session ? data.session.fee_types : [],
          fund_products:
            'fund_products' in data.session
              ? data.session.fund_products.map((product: ClientEntity) => ({
                  ...product,
                  text: getTextFromEntity(product),
                }))
              : [],
          userLoading: false,
        })

        Sentry.getCurrentScope().setUser({ email: this.user?.email })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getUserSettings() {
      if (!this.user || !this.token) {
        return
      }

      try {
        const response = await UserSettingsService.getUserSettings(
          this.user.id,
          this.token.Authorization
        )
        this.setUserSettings(response.data.data)
      } catch (err) {
        throw new HttpError(err)
      }
    },

    setPermissions(permissions: Permission[], relations: RootUserRelation[]) {
      const permissionsMap: PermissionMap = {}

      permissions.forEach((permission) => {
        const relation =
          relations.find((rel) => rel.id == permission.root_user_relation_id) ||
          null
        if (relation) {
          const key = relation.entity_id
          if (!(key in permissionsMap)) {
            permissionsMap[key] = []
          }
          permissionsMap[key].push(permission.permission_id)
        }
      })

      this.permissions = permissionsMap
    },

    setUserSettings(newSettings: Partial<UserSettings>) {
      const additional = this.settings.additional
      if (newSettings.additional) {
        // accept only valid themes values
        if (
          'theme' in newSettings.additional &&
          newSettings.additional.theme &&
          !themeValues.includes(newSettings.additional.theme)
        ) {
          newSettings.additional.theme = themeValues[0]
        }

        // remove null values (except tour_flow) for use default values
        const nullableKeys = [
          'tour_flow',
          'dashboard_benchmark',
          'default_session',
        ]
        Object.keys(newSettings.additional).forEach((key) => {
          if (
            nullableKeys.indexOf(key) == -1 &&
            newSettings.additional![key] == null
          ) {
            delete newSettings.additional![key]
          }
        })
        Object.assign(additional, newSettings.additional)
      }

      this.settings = {
        ...this.settings,
        ...newSettings,
        additional,
      }
    },

    async saveUserSettings(newSettings: Partial<UserSettings>) {
      const { settings, user } = this
      if (!user) {
        return
      }

      this.setUserSettings(newSettings)

      try {
        await UserSettingsService.updateUserSettings(user.id, newSettings)
      } catch (err) {
        this.setUserSettings(settings)
        throw new HttpError(err)
      }
    },

    async saveUserAdditionalSettings(newSettings: UserSettings['additional']) {
      return this.saveUserSettings({
        additional: {
          ...this.settings.additional,
          ...newSettings,
        },
      })
    },

    async changeCurrency(type: 'fiat' | 'crypto', currencyId: string) {
      const key = type === 'fiat' ? 'base_currency_id' : 'base_crypto_id'

      return this.saveUserSettings({
        [key]: currencyId,
      })
    },

    async changeEntity(entityId: string) {
      ApiService.cancelCurrentRequests()

      this.currentEntityId = entityId
      UserService.setCurrentSessionEntityId(entityId)
      UserService.setLastVisibleEntityId(entityId)
    },

    async changePassword(
      email: string,
      old_password: string,
      new_password: string
    ) {
      const old_hashed = UserService.hashPassword(old_password, email)
      const new_hashed = UserService.hashPassword(
        new_password,
        email.toLowerCase()
      )

      try {
        await UserService.changePassword(email, old_hashed, new_hashed)
      } catch (error) {
        throw new HttpError(error)
      }
    },

    async updateUserProfile(data: UpdateProfileParams) {
      if (!this.user) {
        return
      }

      let change_email = false
      let new_email: string | null = null
      let old_password: string | null = null
      let new_password: string | null = null

      const { password, email, ...postData } = data

      if (email !== this.user.email && password) {
        change_email = true
        new_email = email
        old_password = UserService.hashPassword(password, this.user.email)
        new_password = UserService.hashPassword(password, email.toLowerCase())
      }

      try {
        await UserService.updateProfile({
          ...postData,
          change_email,
          new_email,
          old_password,
          new_password,
        })
        this.user = {
          ...this.user,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        }
      } catch (error) {
        throw new HttpError(error)
      }
    },

    async saveTourFlow(tourFlow: TourFlow) {
      return this.saveUserAdditionalSettings({
        tour_flow: tourFlow,
      })
    },

    async getDefaultCurrencies() {
      const baseCurrencyId =
        this.entity?.fund_product?.base_currency_id ||
        this.clientEntity?.base_currency_id

      if (!baseCurrencyId) {
        return
      }

      const globalStore = useGlobalStore()
      const baseCryptoId = globalStore.currencyBySymbol('BTC')?.id

      if (
        this.settings.base_currency_id != baseCurrencyId ||
        this.settings.base_crypto_id != baseCryptoId
      ) {
        return this.saveUserSettings({
          base_currency_id: baseCurrencyId,
          base_crypto_id: baseCryptoId,
        })
      }
    },

    addOwner(owner: Entity) {
      this.owners.push({
        ...owner,
        text: getTextFromEntity(owner),
      })
    },

    updateOwner(owner: Entity) {
      const idx = this.owners.findIndex((o) => o.id == owner.id)
      if (idx !== -1) {
        const newOwner = {
          ...this.owners[idx],
          ...owner,
        }
        newOwner.text = getTextFromEntity(newOwner)
        this.owners[idx] = newOwner
      }
    },

    removeOwner(entity_id: string) {
      const idx = this.owners.findIndex((o) => o.id == entity_id)
      this.owners.splice(idx, 1)
    },

    addFeeType(feeType: FeeType) {
      this.fee_types.push(feeType)
    },

    updateFeeType(feeTypeId: string, data: FeeType) {
      const idx = this.fee_types.findIndex(
        (feeType) => feeType.id === feeTypeId
      )
      if (idx !== -1) {
        const feeType = this.fee_types[idx]
        Object.keys(data).forEach((key) => {
          Object.assign(feeType, { [key]: data[key] || feeType[key] })
        })
      }
    },

    removeFeeType(feeTypeId: string) {
      const idx = this.fee_types.findIndex(
        (feeType) => feeType.id === feeTypeId
      )
      if (idx !== -1) {
        this.fee_types.splice(idx, 1)
      }
    },
  },

  getters: {
    entities(state): Entity[] {
      return state.owners
    },

    productEntities(): Entity[] {
      return this.entities.filter(
        (entity) => entity && entity.type != 'root_user'
      )
    },

    entitiesGrouped(): EntitiesGroup[] {
      const grouped: GroupedEntities = this.productEntities.reduce(
        (acc: GroupedEntities, entity: Entity) => {
          if (!(entity.type in acc)) {
            acc[entity.type] = {
              icon: 'Box',
              text: friendlyEntityTypes[entity.type],
              entities: [],
            }
          }

          acc[entity.type]?.entities.push(entity)
          return acc
        },
        {}
      )

      return Object.values(grouped).sort((a, b) => {
        return a.text.localeCompare(b.text)
      })
    },

    clientEntity(state): ClientEntity | null {
      const entities = state.fund_products
      const { currentEntityId } = state

      if (entities.length == 0) {
        return null
      }

      const selectedEntity =
        currentEntityId && entities.find((e) => e.entity_id == currentEntityId)

      return selectedEntity || entities[0]
    },

    entity(state): Entity | null {
      const entities = this.productEntities
      const { currentEntityId } = state

      if (entities.length == 0) {
        return null
      }

      const selectedEntity =
        currentEntityId && entities.find((e) => e.id == currentEntityId)

      return selectedEntity || entities[0]
    },

    entityId(): string | null {
      return this.entity?.id || this.clientEntity?.entity_id || null
    },

    entityTimezone(): Timezone | null {
      if (!this.entity) {
        return null
      }
      return this.getEntityTimezone(this.entity)
    },

    fundProductId(): string | null {
      return (
        this.entity?.fund_product?.id ||
        this.clientEntity?.fund_product_id ||
        null
      )
    },

    entityById(state) {
      return (entity_id: string): Entity | null => {
        return state.owners.find((entity) => entity.id === entity_id) || null
      }
    },

    clientEntityById(state) {
      return (entity_id: string): ClientEntity | null => {
        return (
          state.fund_products.find(
            (entity) => entity.entity_id === entity_id
          ) || null
        )
      }
    },

    entityByRootUserId() {
      return (root_user_id: string): Entity | null => {
        return (
          this.entities.find((entity) => {
            return entity.root_user && entity.root_user.id === root_user_id
          }) || null
        )
      }
    },

    isFundProduct(): boolean {
      const { entity } = this
      return entity != null && entity.type == 'fund_product'
    },

    currencyFiat(state): Currency | null {
      if (!state.settings.base_currency_id) {
        return null
      }

      const globalStore = useGlobalStore()
      return globalStore.currencyById(state.settings.base_currency_id)
    },

    currencyCrypto(state): Currency | null {
      if (!state.settings.base_crypto_id) {
        return null
      }

      const globalStore = useGlobalStore()
      return globalStore.currencyById(state.settings.base_crypto_id)
    },

    currencyFiatSymbol(): string {
      return this.currencyFiat?.symbol || '-'
    },

    currencyCryptoSymbol(): string {
      return this.currencyCrypto?.symbol || '-'
    },

    currencyHash(): string | null {
      const currencyFiat = this.currencyFiat
      const currencyCrypto = this.currencyCrypto
      if (!currencyCrypto || !currencyFiat) {
        return null
      }
      return `${currencyCrypto.id}||${currencyFiat.id}`
    },

    currentFundProductId(): string | null {
      const entity = this.entity

      if (entity && 'fund_product' in entity && entity.fund_product) {
        return entity.fund_product.id
      }

      return null
    },

    sessionTimeout(state): TimeoutOption {
      const globalStore = useGlobalStore()
      const defaultTimeout = globalStore.sessionTimeoutOptions[0]

      if (state.settings.session_timeout) {
        return (
          globalStore.sessionTimeoutOptions.find((option) => {
            return option.value == state.settings.session_timeout
          }) || defaultTimeout
        )
      }
      return defaultTimeout
    },

    tablePagination(state): number {
      return state.settings.additional.table_pagination || 25
    },

    accountName(state): string {
      const { user } = state
      if (user?.first_name) {
        return user.first_name
      } else if (user?.email) {
        return user.email.split('@')[0]
      }
      return ''
    },

    userName(state): string {
      const { user } = state
      if (!user) {
        return ''
      }
      return `${user.last_name} ${user.first_name}`
    },

    dateTimeFormat(state): string {
      return `${state.settings.additional.date_format} HH:mm:ss`
    },

    fundCurrency(): Currency | null {
      const entity = this.entity

      if (!entity || !entity.fund_product) {
        return null
      }

      const globalStore = useGlobalStore()

      const base_currency_id = entity.fund_product.base_currency_id
      return globalStore.currencyById(base_currency_id) || null
    },

    fundCurrencySymbol(): string {
      return this.fundCurrency?.symbol || '-'
    },

    hasProduct(): boolean {
      return this.productEntities.length > 0
    },

    hasPermission(state) {
      return (permissionId: number): boolean => {
        // Superadmin can access everything, expect 293 permission (enable/disable DeFi services)
        if (state.root_user?.role === 'superadmin' && permissionId !== 293) {
          return true
        }
        const permissions = state.permissions

        const entityId = this.entityId

        if (!entityId) {
          return false
        }

        return (
          entityId in permissions &&
          permissions[entityId].indexOf(permissionId) !== -1
        )
      }
    },

    hasPackage(state) {
      return (packageId: string[] | string | number[] | number): boolean => {
        if (Array.isArray(packageId)) {
          const hasSinglePackage = (element: string | number): boolean => {
            return state.packages.indexOf(Number(element)) != -1
          }
          return packageId.some(hasSinglePackage)
        } else {
          return state.packages.indexOf(Number(packageId)) != -1
        }
      }
    },

    hasMultipleAccounts(state): boolean {
      if (state.relations.length > 1) {
        return true
      }

      return state.relations.some((relation) => {
        if (relation.entities.length > 1) {
          return true
        }
        return relation.entities.some((entity) => entity.roles.length > 1)
      })
    },

    hasRelation(state) {
      return (relation: SessionRole): boolean => {
        return state.relations.some((rel) => {
          if (relation == 'admin' && rel.is_superadmin) {
            return true
          }

          return rel.entities.some(
            (entity) => entity.roles.indexOf(relation) != -1
          )
        })
      }
    },

    relationByEntityIdAndRole(state) {
      return (entity_id: string, role: Role): UserRootRelation | null => {
        return (
          state.relations.find((rel) => {
            const entity = rel.entities.find((e) => e.entity_id == entity_id)

            if (!entity) {
              return false
            }

            return (
              (role == 'superadmin' && rel.is_superadmin) ||
              (entity.roles as Role[]).indexOf(role) != -1
            )
          }) || null
        )
      }
    },

    relationFromDefaultSession(state): UserRootRelation | null {
      const defaultSession = state.settings.additional.default_session

      if (!defaultSession) {
        return null
      }

      return this.relationByEntityIdAndRole(
        defaultSession.entity_id,
        defaultSession.role
      )
    },

    noPermissionsAdmin(state): boolean {
      const isAdmin = state.root_user?.role == 'admin'
      const hasPermissions = Object.keys(state.permissions).length > 0
      return isAdmin && !hasPermissions
    },

    noPackages(state): boolean {
      return state.packages.length == 0
    },

    homeRoute(state): string {
      const currentView = state.currentView
      let routeName = 'DashboardView'
      switch (currentView) {
        case 'client':
          routeName = 'ClientFundView'
          break
        case 'referral':
          routeName = 'ReferrerView'
          break
        // no default
      }
      return routeName
    },

    getEntityTimezone() {
      return (entity: Entity | ClientEntity): Timezone | null => {
        const timezoneId =
          'timezone_id' in entity
            ? entity.timezone_id
            : entity[entity.type] && entity[entity.type]?.timezone_id

        if (timezoneId) {
          const globalStore = useGlobalStore()
          return globalStore.timezoneById(timezoneId)
        }
        return null
      }
    },

    dateToEntityTzString() {
      return (date: Date, entity_id: string): string => {
        // Converts date into a date ISO string with
        // offset set by the entity timezone
        const entity =
          this.entityById(entity_id) || this.clientEntityById(entity_id)
        let timezone: Timezone | null = null
        let datetime = dateToString(date)

        if (entity) {
          timezone = this.getEntityTimezone(entity)
        }

        if (date && timezone && timezone.time_offset) {
          const offset = militaryGMTOffsetFromNumeric(
            parseFloat(timezone.time_offset)
          )
          datetime = format(date, "yyyy-MM-dd'T'HH:mm:ss") + offset
        }

        return datetime
      }
    },

    dateToUTCString() {
      return (date: Date, entity_id: string): string => {
        // Converts Date object into a date ISO string
        // with offset 00:00
        const entity =
          this.entityById(entity_id) || this.clientEntityById(entity_id)
        let timezone: Timezone | null = null
        let string = dateToString(date)

        if (entity) {
          timezone = this.getEntityTimezone(entity)
        }

        if (date && timezone && timezone.time_offset) {
          const minutes = parseFloat(timezone.time_offset) * 60
          date = addMinutes(date, date.getTimezoneOffset())
          date = subMinutes(date, minutes)
          string = format(date, "yyyy-MM-dd'T'HH:mm:ss'+00:00'")
        }

        return string
      }
    },

    stringToEntityTzDate() {
      return (string: string, entity_id?: string | null): Date => {
        // Converts ISO string into a Date() object in regard to selected entity
        const entity = entity_id
          ? this.entityById(entity_id) || this.clientEntityById(entity_id)
          : this.entity || this.clientEntity
        let timezone: Timezone | null = null
        let date = stringToUTCDate(string)

        if (entity) {
          timezone = this.getEntityTimezone(entity)
        }

        if (date && timezone && timezone.time_offset) {
          // Adjust time
          const minutes = parseFloat(timezone.time_offset) * 60
          date = addMinutes(date, date.getTimezoneOffset())
          date = addMinutes(date, minutes)
        }

        return date
      }
    },

    dateWithTzOffset() {
      return (date: Date, entity_id: string): Date => {
        const entity =
          this.entityById(entity_id) || this.clientEntityById(entity_id)
        let timezone: Timezone | null = null

        if (entity) {
          timezone = this.getEntityTimezone(entity)
        }

        if (timezone && timezone.time_offset) {
          // Adjust time
          const minutes = parseFloat(timezone.time_offset) * 60
          date = addMinutes(date, date.getTimezoneOffset())
          date = addMinutes(date, minutes)
        }

        return date
      }
    },

    getEntityDate() {
      return (date: Date): Date => {
        const entityId = this.entityId
        if (!entityId) {
          return date
        }
        return this.dateWithTzOffset(date, entityId)
      }
    },

    utcLastClose() {
      return (entity: Entity | ClientEntity): string => {
        return this.utcCloseFromDate(new Date(), entity, true)
      }
    },

    utcCloseFromDate() {
      return (
        date: Date,
        entity: Entity | ClientEntity | null,
        notFuture = false
      ): string => {
        const timezone = entity ? this.getEntityTimezone(entity) : null

        const globalStore = useGlobalStore()
        return globalStore.utcCloseByTimezone(
          date,
          timezone?.id ?? null,
          notFuture
        )
      }
    },

    utcOpenFromDate() {
      return (date: Date, entity: Entity | ClientEntity | null): string => {
        let timezoneId: string | null = null

        if (entity) {
          timezoneId =
            'timezone_id' in entity
              ? entity.timezone_id
              : (entity[entity.type] && entity[entity.type]?.timezone_id) ||
                null
        }

        const globalStore = useGlobalStore()
        return globalStore.utcOpenByTimezone(date, timezoneId)
      }
    },

    formatDateStringInTimeZone() {
      return (dateString: string | null, format: string): string => {
        const entityId = this.entityId
        if (!dateString || !entityId) {
          return '-'
        }

        const date = this.stringToEntityTzDate(dateString, entityId)
        return date ? formatDateHelper(date, format) : '-'
      }
    },

    activeTourFlow(state): TourFlow | null {
      const flow = state.settings.additional.tour_flow
      if (flow === null) {
        const isTracker =
          (this.hasPackage('2') || this.hasPackage('37')) &&
          !(this.hasPackage('3') || this.hasPackage('43'))
        const portfolio = this.productEntities.find(
          (entity: Entity) => entity.type == 'portfolio'
        )

        if (isTracker && !portfolio) {
          return {
            scenario_key: 'onboarding',
            current_step: 0,
            finished: false,
          }
        }
      }

      return flow?.finished ? null : flow
    },

    weekStartForApi(state): DayOfWeek {
      return ((state.settings.additional.week_starts_on + 6) % 7) as DayOfWeek
    },

    currentFundFeeTypes(state): FeeType[] {
      const entity = this.entity
      if (!entity || !entity.fund_product) {
        return []
      }

      return state.fee_types.filter((feeType) => {
        return (
          !('fund_product_id' in feeType) ||
          feeType.fund_product_id == entity.fund_product?.id
        )
      })
    },

    feeTypeById(state) {
      return (id: string): FeeType | null => {
        return state.fee_types.find((type) => type.id === id) || null
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
