<template>
  <div>
    <PanelToolbar
      :name="currentMonthName"
      :aria-label-prev="$t('ui.components.datepicker.previous_month')"
      :aria-label-next="$t('ui.components.datepicker.next_month')"
      @prev="prev"
      @next="next"
      @next-panel="$emit('next-panel')"
    />

    <div class="calendar-days-name">
      <div v-for="(name, i) in dateNames" :key="i" class="day">
        <span>{{ name }}</span>
      </div>
    </div>
    <div class="calendar-days" @mouseleave="$emit('hover-date', null)">
      <div
        v-for="(day, i) in days"
        :key="i"
        class="day"
        :class="dayClasses(day)"
        @click.stop="selectDay(day)"
        @mouseover="hoverizeDay(day)"
      >
        <span>{{ day.date.getDate() }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'
import {
  addDays,
  endOfDay,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  isSameDay,
  isWithinInterval,
  startOfDay,
  isLastDayOfMonth,
  isFirstDayOfMonth,
  type Locale,
} from 'date-fns'
import { enUS } from 'date-fns/locale'

import { useUserStore } from '@/stores/user'

import PanelToolbar from './PanelToolbar.vue'

import PanelMixin from './panelMixin'
import DatesMixin from '@/mixins/dates'

interface Day {
  date: Date
  selectable: boolean
  isPrevMonth: boolean
  isNextMonth: boolean
}

export default defineComponent({
  components: {
    PanelToolbar,
  },

  mixins: [PanelMixin, DatesMixin],

  props: {
    hovered: {
      type: Date as PropType<Date | null>,
      default: null,
    },
  },

  emits: ['select-date', 'hover-date', 'update:modelValue', 'next-panel'],

  computed: {
    ...mapState(useUserStore, {
      weekStartsOn: (store) => store.settings.additional.week_starts_on,
    }),
    locale(): Locale | undefined {
      // NOTE: For more languages
      // const lc = this.$i18n.locale
      // if (lc == 'en_') {
      //   return enUS
      // }
      return enUS
    },
    currentMonthName(): string {
      return format(this.modelValue, 'MMMM yyyy', {
        locale: this.locale,
      })
    },
    days(): Day[] {
      const days: Day[] = []
      const lastDayOfMonth = endOfMonth(this.modelValue)
      const firstDayOfMonth = startOfMonth(this.modelValue)
      const firstDay = startOfWeek(firstDayOfMonth, {
        weekStartsOn: this.weekStartsOn,
      })
      const lastDay = endOfWeek(lastDayOfMonth, {
        weekStartsOn: this.weekStartsOn,
      })
      let day = firstDay
      while (!isAfter(day, lastDay)) {
        const isAllowed = this.isDateAllowed(day)
        const _isSameMonth = isSameMonth(this.modelValue, day)
        const isPrevMonth = !_isSameMonth && isBefore(day, this.modelValue)
        const isNextMonth = !_isSameMonth && isAfter(day, this.modelValue)
        days.push({
          date: day,
          selectable: isAllowed,
          isPrevMonth,
          isNextMonth,
        })
        day = addDays(day, 1)
      }
      return days
    },
    dateNames(): string[] {
      const days = this.days.slice(0, 7)
      const week: string[] = []
      for (const day of days) {
        week.push(format(day.date, 'eeeeee', { locale: this.locale }))
      }
      return week
    },
    marginalDays(): number[] {
      return [this.weekStartsOn + 1, ((this.weekStartsOn + 6) % 7) + 1]
    },
  },

  methods: {
    dayClasses(day: Day): string[] {
      const classes: string[] = []
      const now = this.getEntityCurrentDate()

      if (!day.selectable) {
        classes.push('is-disabled')
      }

      if (day.isPrevMonth || day.isNextMonth) {
        classes.push('is-another-month')
        return classes
      }

      if (isSameDay(day.date, now)) {
        classes.push('is-today')
      }
      if (this.hovered && isSameDay(day.date, this.hovered)) {
        classes.push('is-hovered')
      }

      const dayOfWeek = parseInt(format(day.date, 'e'), 10)
      if (dayOfWeek == this.marginalDays[0] || isFirstDayOfMonth(day.date)) {
        classes.push('is-first-of-week')
      }
      if (dayOfWeek == this.marginalDays[1] || isLastDayOfMonth(day.date)) {
        classes.push('is-last-of-week')
      }

      // Classes for single day datepicker
      if (this.selected.some((selected) => isSameDay(day.date, selected))) {
        classes.push('is-selected-single')
      }

      if (this.selected.length == 2) {
        const from = this.selected[0]
        const to = this.selected[1]
        if (
          from &&
          to &&
          isWithinInterval(day.date, {
            start: startOfDay(from),
            end: endOfDay(to),
          })
        ) {
          classes.push('is-selected')
        }
        if (isSameDay(day.date, from)) {
          classes.push('is-first-range')
        } else if (isSameDay(day.date, to)) {
          classes.push('is-last-range')
        }
      }

      if (this.hovered && this.selected.length == 1) {
        const from = this.selected[0]
        const start = isBefore(from, this.hovered) ? from : this.hovered
        const end = start == from ? this.hovered : from

        if (
          isWithinInterval(day.date, {
            start,
            end,
          })
        ) {
          classes.push('is-in-range')
        }
        if (isSameDay(day.date, start)) {
          classes.push('is-first-range')
        } else if (isSameDay(day.date, end)) {
          classes.push('is-last-range')
        }
      }

      return classes
    },
    changeMonth(diff: number): void {
      const newDate = addMonths(this.modelValue, diff)
      this.$emit('update:modelValue', newDate)
    },
    prev(): void {
      this.changeMonth(-1)
    },
    next(): void {
      this.changeMonth(1)
    },
    selectDay(day: Day): void {
      if (day.selectable) {
        this.$emit('update:modelValue', day.date)
        this.$emit('select-date', day.date)
      }
    },
    hoverizeDay(day: Day): void {
      if (day.selectable) {
        this.$emit('hover-date', day.date)
      } else {
        this.$emit('hover-date', null)
      }
    },
  },
})
</script>
