<template>
  <div class="bg-red-alpha rounded px-2 py-1">
    <div
      class="flex justify-between cursor-pointer"
      @click="showDetails = !showDetails"
    >
      <span class="font-mono">
        [{{ date }}] [{{ status }}]
        {{ url }}
      </span>
      <span class="inline-flex items-center shrink-0">
        {{
          showDetails
            ? $t('ui.bug_reporting.hide_details')
            : $t('ui.bug_reporting.show_details')
        }}
        <SvgIcon
          icon="ArrowDown"
          class="ml-1"
          :class="{
            'transform rotate-180': showDetails,
          }"
        />
      </span>
    </div>

    <TransitionFadeExpand>
      <div v-if="showDetails" class="my-2 bg-red-alpha px-2 py-1 rounded">
        <LabelValue :label="$t('ui.bug_reporting.correlation_id')">
          <span v-if="error.correlation_id" class="inline-flex">
            {{ error.correlation_id }}
            <SvgIcon
              v-tippy="{
                delay: [500, 0],
                content: $t('ui.common.copy_to_clipboard'),
              }"
              icon="Copy"
              class="flex-shrink-0 ml-1 cursor-pointer"
              block
              @click="clipboard.copyText(error.correlation_id)"
            />
          </span>
        </LabelValue>
        <LabelValue :label="$t('ui.bug_reporting.method')" :value="method" />
        <LabelValue
          :label="$t('ui.bug_reporting.post_data')"
          :value="postData"
        />
        <LabelValue
          :label="$t('ui.bug_reporting.response')"
          :value="response"
        />
        <LabelValue
          :label="$t('ui.bug_reporting.error_message')"
          :value="error.message"
        />
      </div>
    </TransitionFadeExpand>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue'
import { format } from 'date-fns'
import { useCKClipboard } from '@/composables/ck_clipboard'
import { LabelValue } from '@/components/misc/LabelValue'
import SvgIcon from '@/components/misc/SvgIcon'
import { TransitionFadeExpand } from '@/components/misc/Transitions'
import type { RequestError } from './bug_report'

const props = defineProps({
  error: {
    type: Object as PropType<RequestError>,
    required: true,
  },
})

const clipboard = useCKClipboard()

const showDetails = ref(false)

const status = computed(() => props.error.status ?? '---')
const date = computed(() => format(props.error.date, 'yyyy-MM-dd HH:mm:ss'))
const method = computed(() => props.error.method?.toUpperCase() ?? 'UKNOWN')
const url = computed(() =>
  props.error.url ? stripDomain(props.error.url) : 'unknown'
)

const postData = computed(() => props.error.data || '-')
const response = computed(() => formatJSON(props.error.response) || '-')

function formatJSON(data: any) {
  return JSON.stringify(data, null, 2)
}

function stripDomain(url: string) {
  return url.replace(/^https?:\/\/[^/]+/, '')
}
</script>
