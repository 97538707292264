import { useBroadcast } from '@/composables/broadcast'
import { EVENT_LOGGED_OUT, EVENT_SESSION_CHANGED } from '@/constants/broadcast'
import { getHash } from '@/helpers/strings'
import { HttpError } from '@/helpers/errors'
import { useUserStore } from '@/stores/user'
import ApiService from '@/services/api.service'
import { currentDateInUTCString } from '@/services/date.service'
import TokenService from '@/services/user/token'

import type { AxiosResponse, AxiosRequestConfig } from 'axios'
import type { EmptyDataResponse } from '@/types/services/common'
import type {
  GeneralResponse,
  LoginParams,
  LoginResponse,
  MeResponse,
  RefreshTokenResponse,
  RegisterAuthData,
  RegisterAuthResponse,
  RegisterData,
  RegisterResponse,
  RelationsResponse,
  StartSessionParams,
  Token,
  UpdateProfileData,
} from '@/types/services/user'

const CURRENT_ENTITY_KEY = 'current_entity'
const LAST_VISIBLE_ENTITY_KEY = 'last_visible_entity_id'
const AUTH_ERROR_KEY = 'auth_errors'

function authorize_user(token: Token) {
  TokenService.saveAuthToken(token.Authorization)
  TokenService.saveRefreshToken(token.refresh_token)
  ApiService.setHeader()

  ApiService.mount401Interceptor()
}

class UserService {
  /**
   * Load user
   **/
  static getMe(): Promise<AxiosResponse<MeResponse>> {
    try {
      return ApiService.get('/api/v1/me')
    } catch (error) {
      throw new HttpError(error)
    }
  }

  /**
   * Load general info
   **/
  static getGeneral(): Promise<AxiosResponse<GeneralResponse>> {
    try {
      return ApiService.get('/api/v2/general')
    } catch (error) {
      throw new HttpError(error)
    }
  }

  /**
   * Login the user and store the access token to TokenService.
   **/
  static async login(
    email: string,
    password: string,
    lowercase_password?: string | null
  ): Promise<LoginResponse> {
    try {
      const params: LoginParams = {
        email,
        password,
        type: 0,
      }

      if (lowercase_password) {
        params.lowercase_password = lowercase_password
      }

      const response = (await ApiService.post(
        '/api/v2/users/login',
        params
      )) as AxiosResponse<LoginResponse>

      return response.data
    } catch (error) {
      throw new HttpError(error)
    }
  }

  /**
   * Register new user
   **/
  static async registerUser(data: RegisterData): Promise<RegisterResponse> {
    try {
      ApiService.unmount401Interceptor()

      const response = await ApiService.post('/api/v1/users/register', {
        ...data,
        request_timestamp: currentDateInUTCString(),
      })
      return response.data
    } catch (error) {
      throw new HttpError(error)
    } finally {
      ApiService.mount401Interceptor()
    }
  }

  static async registerAuthUser(
    data: RegisterAuthData
  ): Promise<RegisterAuthResponse> {
    try {
      ApiService.unmount401Interceptor()

      const response = await ApiService.post('/api/v1/users/register_auth', {
        ...data,
        request_timestamp: currentDateInUTCString(),
      })
      return response.data
    } catch (error) {
      throw new HttpError(error)
    } finally {
      ApiService.mount401Interceptor()
    }
  }

  /**
   * Change password
   *
   * @param email user email which is used fo sign in
   * @param old_password hashed current password
   * @param new_password hashed new password
   * @returns Promise<AxiosResponse<EmptyDataResponse>>
   */
  static changePassword(
    email: string,
    old_password: string,
    new_password: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    ApiService.unmount401Interceptor()
    try {
      return ApiService.post('/api/v1/users/change_password', {
        email: email,
        old_password: old_password,
        new_password: new_password,
      })
    } catch (error) {
      throw new HttpError(error)
    } finally {
      ApiService.mount401Interceptor()
    }
  }

  /**
   * Update user's profile
   */
  static updateProfile(
    data: UpdateProfileData
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.put('/api/v1/user', data)
  }

  /**
   * Refresh the access token.
   **/
  static async refreshToken(): Promise<string> {
    const refreshToken = TokenService.getRefreshToken()

    const requestData: AxiosRequestConfig = {
      method: 'post',
      url: ApiService.getResourceUrl('/api/v1/me/refresh_token'),
      data: {
        refresh_token: refreshToken,
      },
    }

    try {
      const response = (await ApiService.customRequest(
        requestData
      )) as AxiosResponse<RefreshTokenResponse>
      TokenService.saveAuthToken(response.data.data.Authorization)
      // Update the header in ApiService
      ApiService.setHeader()

      return response.data.data.Authorization
    } catch (error) {
      console.warn('Refresh token failed!')
      await UserService.logout('?expired=true', true, false)
      return Promise.reject(error)
    }
  }

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  static async logout(
    urlParams = '',
    redirect = true,
    endSession = true
  ): Promise<void> {
    // Remove the token and remove Authorization header from Api Service as well
    const userStore = useUserStore()
    const broadcast = useBroadcast()
    try {
      if (endSession && userStore.token) {
        await ApiService.post('/api/v2/users/logout', null)
        userStore.isLoggingOut = true
      }
    } catch (err) {
      console.debug(err)
      console.warn('Something went wrong when logging out. Session not ended.')
    } finally {
      TokenService.removeAuthToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      ApiService.unmount401Interceptor()
      sessionStorage.removeItem(CURRENT_ENTITY_KEY)
      broadcast.emit(EVENT_LOGGED_OUT)

      if (redirect) {
        window.location.href = '/signin' + urlParams
      } else {
        userStore.isLoggingOut = false
      }
    }
  }

  /**
   * Sends the token received from a confirmation mail
   */
  static async confirmEmail(
    token: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    ApiService.unmount401Interceptor()
    try {
      return await ApiService.post('/api/v1/users/verify_email', {
        token_hash: token,
      })
    } catch (error) {
      throw new HttpError(error)
    } finally {
      ApiService.mount401Interceptor()
    }
  }

  /**
   * Request new verification email
   */
  static resendVerificationEmail(
    email: string
  ): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.post('/api/v1/users/send_verification_mail', { email })
  }

  /**
   * Get user relations with roots, entities and roles in them
   * @param auth_token API auth token
   * @returns relations
   */
  static getRelations(
    auth_token: string
  ): Promise<AxiosResponse<RelationsResponse>> {
    return ApiService.customRequest({
      url: ApiService.getResourceUrl('/api/v2/users/relations'),
      method: 'get',
      headers: {
        Authorization: auth_token,
      },
    })
  }

  /**
   * Starts session with selected user_id and root_id
   */
  static async startSession(
    token: Token,
    params: StartSessionParams
  ): Promise<void> {
    ApiService.unmount401Interceptor()
    const broadcast = useBroadcast()
    try {
      authorize_user(token)
      await ApiService.post('/api/v1/users/start_session', params)
      broadcast.emit(EVENT_SESSION_CHANGED, { msg: 'asdasd' })
    } catch (error) {
      throw new HttpError(error)
    } finally {
      ApiService.mount401Interceptor()
    }
  }

  static hashPassword(password: string, email: string): string {
    return getHash(`${password}||${email}`)
  }

  static getFromUrlParamsEntityId(): string | null {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('entity_id')
  }

  static getCurrentSessionEntityId(): string | null {
    return sessionStorage.getItem(CURRENT_ENTITY_KEY)
  }

  static setCurrentSessionEntityId(entity_id: string) {
    sessionStorage.setItem(CURRENT_ENTITY_KEY, entity_id)
  }

  static getLastVisibleEntityId(): string | null {
    return localStorage.getItem(LAST_VISIBLE_ENTITY_KEY)
  }

  static setLastVisibleEntityId(entity_id: string) {
    localStorage.setItem(LAST_VISIBLE_ENTITY_KEY, entity_id)
  }

  static saveAuthErrors(error: string[]) {
    sessionStorage.setItem(AUTH_ERROR_KEY, JSON.stringify(error))
  }

  static getAuthErrors(): string[] {
    const errMessage = sessionStorage.getItem(AUTH_ERROR_KEY)
    if (!errMessage) {
      return []
    }

    sessionStorage.removeItem(AUTH_ERROR_KEY)
    return JSON.parse(errMessage)
  }
}

export default UserService
