import { type RouteRecordRaw } from 'vue-router'

const ComponentsTest = () => import('@/views/DEV_ONLY/ComponentsTest/Index.vue')
const ComponentsTestApiTest = () =>
  import('@/views/DEV_ONLY/ComponentsTest/ApiTest.vue')
const ComponentsTestAlert = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Alert.vue')
const ComponentsTestAnimatedNumber = () =>
  import('@/views/DEV_ONLY/ComponentsTest/AnimatedNumber.vue')
const ComponentsArrowLinkTest = () =>
  import('@/views/DEV_ONLY/ComponentsTest/ArrowLink.vue')
const ComponentsTestAvatar = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Avatar.vue')
const ComponentsTestBadge = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Badge.vue')
const ComponentsTestBarsView = () =>
  import('@/views/DEV_ONLY/ComponentsTest/BarsView.vue')
const ComponentsTestBasicInput = () =>
  import('@/views/DEV_ONLY/ComponentsTest/BasicInput.vue')
const ComponentsTestButton = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Button.vue')
const ComponentsTestButtonDropdown = () =>
  import('@/views/DEV_ONLY/ComponentsTest/ButtonDropdown.vue')
const ComponentsTestCarousel = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Carousel.vue')
const ComponentsTestCharts = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Charts.vue')
const ComponentsTestCheckbox = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Checkbox.vue')
const ComponentsTestCollapsible = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Collapsible.vue')
const ComponentsTestDatePicker = () =>
  import('@/views/DEV_ONLY/ComponentsTest/DatePicker.vue')
const ComponentsTestDetailCard = () =>
  import('@/views/DEV_ONLY/ComponentsTest/DetailCard.vue')
const ComponentsTestDisplayField = () =>
  import('@/views/DEV_ONLY/ComponentsTest/DisplayField.vue')
const ComponentsTestExpandPanel = () =>
  import('@/views/DEV_ONLY/ComponentsTest/ExpandPanel.vue')
const ComponentsTestPagination = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Pagination.vue')
const ComponentsTestPhoneInput = () =>
  import('@/views/DEV_ONLY/ComponentsTest/PhoneInput.vue')
const ComponentsTestProgressBar = () =>
  import('@/views/DEV_ONLY/ComponentsTest/ProgressBar.vue')
const ComponentsTestRadioGroup = () =>
  import('@/views/DEV_ONLY/ComponentsTest/RadioGroup.vue')
const ComponentsTestSearchInput = () =>
  import('@/views/DEV_ONLY/ComponentsTest/SearchInput.vue')
const ComponentsTestSegmentedControl = () =>
  import('@/views/DEV_ONLY/ComponentsTest/SegmentedControl.vue')
const ComponentsTestSelect = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Select.vue')
const ComponentsTestStepper = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Stepper.vue')
const ComponentsTestSvgIcon = () =>
  import('@/views/DEV_ONLY/ComponentsTest/SvgIcon.vue')
const ComponentsTestSwitchToggle = () =>
  import('@/views/DEV_ONLY/ComponentsTest/SwitchToggle.vue')
const ComponentsTestTable = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Table.vue')
const ComponentsTestTabsView = () =>
  import('@/views/DEV_ONLY/ComponentsTest/TabsView.vue')
const ComponentsTestTags = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Tags.vue')
const ComponentsTestTextArea = () =>
  import('@/views/DEV_ONLY/ComponentsTest/TextArea.vue')
const ComponentsTestTimepicker = () =>
  import('@/views/DEV_ONLY/ComponentsTest/Timepicker.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/components',
    component: ComponentsTest,
    name: 'ComponentsTest',
    meta: {
      public: true,
    },
    children: [
      {
        path: '/components/api-test',
        component: ComponentsTestApiTest,
        name: 'ComponentsTestApiTest',
      },
      {
        path: '/components/alert',
        component: ComponentsTestAlert,
        name: 'ComponentsTestAlert',
      },
      {
        path: '/components/animated-number',
        component: ComponentsTestAnimatedNumber,
        name: 'ComponentsTestAnimatedNumber',
      },
      {
        path: '/components/arrow-link',
        component: ComponentsArrowLinkTest,
        name: 'ComponentsArrowLinkTest',
      },
      {
        path: '/components/avatar',
        component: ComponentsTestAvatar,
        name: 'ComponentsTestAvatar',
      },
      {
        path: '/components/badge',
        component: ComponentsTestBadge,
        name: 'ComponentsTestBadge',
      },
      {
        path: '/components/bars-view',
        component: ComponentsTestBarsView,
        name: 'ComponentsTestBarsView',
      },
      {
        path: '/components/basic-input',
        component: ComponentsTestBasicInput,
        name: 'ComponentsTestBasicInput',
      },
      {
        path: '/components/button',
        component: ComponentsTestButton,
        name: 'ComponentsTestButton',
      },
      {
        path: '/components/button-dropdown',
        component: ComponentsTestButtonDropdown,
        name: 'ComponentsTestButtonDropdown',
      },
      {
        path: '/components/carousel',
        component: ComponentsTestCarousel,
        name: 'ComponentsTestCarousel',
      },
      {
        path: '/components/charts',
        component: ComponentsTestCharts,
        name: 'ComponentsTestCharts',
      },
      {
        path: '/components/checkbox',
        component: ComponentsTestCheckbox,
        name: 'ComponentsTestCheckbox',
      },
      {
        path: '/components/collapsible',
        component: ComponentsTestCollapsible,
        name: 'ComponentsTestCollapsible',
      },
      {
        path: '/components/datepicker',
        component: ComponentsTestDatePicker,
        name: 'ComponentsTestDatePicker',
      },
      {
        path: '/components/detail-card',
        component: ComponentsTestDetailCard,
        name: 'ComponentsTestDetailCard',
      },
      {
        path: '/components/display-field',
        component: ComponentsTestDisplayField,
        name: 'ComponentsTestDisplayField',
      },
      {
        path: '/components/expand-panel',
        component: ComponentsTestExpandPanel,
        name: 'ComponentsTestExpandPanel',
      },
      {
        path: '/components/pagination',
        component: ComponentsTestPagination,
        name: 'ComponentsTestPagination',
      },
      {
        path: '/components/phone-input',
        component: ComponentsTestPhoneInput,
        name: 'ComponentsTestPhoneInput',
      },
      {
        path: '/components/progress-bar',
        component: ComponentsTestProgressBar,
        name: 'ComponentsTestProgressBar',
      },
      {
        path: '/components/radio-group',
        component: ComponentsTestRadioGroup,
        name: 'ComponentsTestRadioGroup',
      },
      {
        path: '/components/search-input',
        component: ComponentsTestSearchInput,
        name: 'ComponentsTestSearchInput',
      },
      {
        path: '/components/select',
        component: ComponentsTestSelect,
        name: 'ComponentsTestSelect',
      },
      {
        path: '/components/stepper',
        component: ComponentsTestStepper,
        name: 'ComponentsTestStepper',
      },
      {
        path: '/components/segmented-control',
        component: ComponentsTestSegmentedControl,
        name: 'ComponentsTestSegmentedControl',
      },
      {
        path: '/components/svg-icon',
        component: ComponentsTestSvgIcon,
        name: 'ComponentsTestSvgIcon',
      },
      {
        path: '/components/switch-toggle',
        component: ComponentsTestSwitchToggle,
        name: 'ComponentsTestSwitchToggle',
      },
      {
        path: '/components/table',
        component: ComponentsTestTable,
        name: 'ComponentsTestTable',
      },
      {
        path: '/components/tabs-view',
        component: ComponentsTestTabsView,
        name: 'ComponentsTestTabsView',
      },
      {
        path: '/components/tags',
        component: ComponentsTestTags,
        name: 'ComponentsTestTags',
      },
      {
        path: '/components/textarea',
        component: ComponentsTestTextArea,
        name: 'ComponentsTestTextArea',
      },
      {
        path: '/components/timepicker',
        component: ComponentsTestTimepicker,
        name: 'ComponentsTestTimepicker',
      },
    ],
  },
]

export default routes
