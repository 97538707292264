<template>
  <div class="font-semibold">
    <span class="mr-2">
      {{ versionNotes.name }}
      ({{ releaseDate }})
    </span>
    <span
      v-if="versionNotes.is_upcoming"
      class="inline-block rounded-full px-2 text-xs bg-orange-alpha text-warning border-warning border"
    >
      {{ $t('ui.system.release_notes.upcoming_release') }}
    </span>
  </div>
  <div v-for="(notes, category) in groupedNotes" :key="category">
    <template v-if="notes.length">
      <div class="font-medium text-sm mt-4 mb-2">
        {{ $t(`ui.system.release_notes.categories.${category}`) }}
      </div>
      <ul class="list-disc list-outside marker:text-brand ml-4">
        <li v-for="(item, idx) in notes" :key="idx" class="text-sm">
          <ReleaseNoteItem :release-note="item" />
        </li>
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'
import { formatDateHelper } from '@/services/date.service'
import { useUserStore } from '@/stores/user'
import ReleaseNoteItem from './ReleaseNoteItem.vue'
import type {
  ReleaseNote,
  ReleaseNotesCategory,
  VersionReleaseNotes,
} from '@/types/services/system'

const props = defineProps({
  versionNotes: {
    type: Object as PropType<VersionReleaseNotes>,
    required: true,
  },
})

const userStore = useUserStore()

const releaseDate = computed(() => {
  const date = userStore.stringToEntityTzDate(
    props.versionNotes.released_at,
    userStore.entity?.id
  )
  return formatDateHelper(date, userStore.settings.additional.date_format)
})

const groupedNotes = computed(() => {
  const grouped: { [key in ReleaseNotesCategory]: ReleaseNote[] } = {
    feat: [],
    fix: [],
  }

  for (const note of props.versionNotes.release_notes) {
    grouped[note.category].push(note)
  }

  return grouped
})
</script>
