<template>
  <div
    :id="id"
    class="crypkit-datepicker"
    :class="{
      inline: inline,
      fullwidth: fullwidth,
      collapsed: showPopup,
      disabled,
      clearable,
    }"
  >
    <Label
      v-if="label !== ''"
      :label="label"
      :required="required"
      :hide-asterisk="hideAsterisk"
      :help="help"
    />

    <div
      ref="button"
      class="crypkit-datepicker-button"
      :class="{
        'bulky-border': bulkyBorder,
      }"
      @click.prevent.stop="openPicker"
    >
      <span :class="fullwidth ? 'w-full' : isRange ? 'w-48' : 'w-24'">
        <DateInput
          :is-range="isRange"
          :selected="selected"
          :placeholder="placeholder"
          :future="future"
          :past="past"
          :allow-from="allowFrom"
          :allow-to="allowTo"
          :disabled="disabled"
          :nullable="nullable"
          @entered-dates="setDatesFromInput"
        />
      </span>

      <div class="crypkit-datepicker-button-icon">
        <span class="clear-datepicker" @click.stop="reset">
          <SvgIcon icon="Close" block />
        </span>
        <SvgIcon icon="Calendar" class="calendar-icon" block />
      </div>

      <Menu
        v-model="showPopup"
        no-max-height
        :position="menuPosition"
        :max-width="isRange && !$breakpoints.mobile ? 642 : 322"
        :anchor-element="$refs.button as HTMLDivElement"
        :include="[$refs.button as HTMLDivElement]"
      >
        <Popup
          :id="id ? `${id}-popup` : null"
          :is-range="isRange && !$breakpoints.mobile"
        >
          <template v-if="isRange">
            <Picker
              v-model:model-value="showedDates[0]"
              v-model:hovered="hovered"
              :selected="selected"
              :future="future"
              :past="past"
              :allow-from="allowFrom"
              :allow-to="allowTo"
              is-range
              @select-date="selectDate"
            />
            <Picker
              v-if="!$breakpoints.mobile"
              v-model:model-value="showedDates[1]"
              v-model:hovered="hovered"
              :selected="selected"
              :future="future"
              :past="past"
              :allow-from="allowFrom"
              :allow-to="allowTo"
              is-range
              is-second
              @select-date="selectDate"
            />

            <PresetsBar
              v-if="presets.length"
              v-model:preset="preset"
              :presets="presets"
            />
          </template>

          <Picker
            v-else
            v-model="showedDates[0]"
            :selected="selected"
            :future="future"
            :past="past"
            :allow-from="allowFrom"
            :allow-to="allowTo"
            @select-date="selectDate"
          />
        </Popup>
      </Menu>
    </div>
  </div>
</template>

<script lang="ts">
import './DatePicker.scss'

import { defineComponent, type PropType } from 'vue'
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
  subDays,
  isSameMonth,
  isAfter,
  isBefore,
} from 'date-fns'

import Label from '@/components/misc/Label'
import SvgIcon from '@/components/misc/SvgIcon'

import DatesMixin from '@/mixins/dates'
import LabelMixin from '@/components/misc/Label/propsMixin'
import LimitsMixin from './limitsMixin'

import DateInput from './DateInput.vue'
import Picker from './Picker.vue'
import Popup from './Popup.vue'
import PresetsBar from './panels/PresetsBar.vue'
import Menu from '@/components/misc/Menu'
import type { MenuPosition } from '@/types/components/menu'

export default defineComponent({
  components: {
    Menu,
    Label,
    SvgIcon,

    DateInput,
    Picker,
    Popup,
    PresetsBar,
  },

  mixins: [DatesMixin, LabelMixin, LimitsMixin],

  props: {
    id: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    from: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    to: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    date: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    defaultPreset: {
      type: String,
      default: null,
    },
    presets: {
      type: Array,
      default: (): string[] => [
        'lastWeek',
        'lastMonth',
        'thisMonth',
        'lastQuarter',
        'lastYear',
        'thisYear',
      ],
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    bulkyBorder: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:date', 'update:from', 'update:to'],

  data() {
    const currentDate = new Date()
    const showedDates = [currentDate, addMonths(currentDate, 1)]

    return {
      showPopup: false,

      initialDate: this.date ? new Date(this.date.getTime()) : null,
      initialFrom: this.from ? new Date(this.from.getTime()) : null,
      initialTo: this.to ? new Date(this.to.getTime()) : null,

      preset: this.from && this.to ? null : this.defaultPreset,

      pickerDate: null,
      secondPickerDate: null,
      hovered: null,

      showedDates,
      oldShowedDates: [...showedDates],

      menuPosition: 'right' as MenuPosition,
    }
  },

  computed: {
    selectedDate: {
      get(): Date | null {
        return this.date
      },
      set(date: Date) {
        this.$emit('update:date', date)
      },
    },
    selectedFrom: {
      get(): Date | null {
        return this.from
      },
      set(date: Date | null) {
        this.$emit('update:from', date)
      },
    },
    selectedTo: {
      get(): Date | null {
        return this.to
      },
      set(date: Date | null) {
        this.$emit('update:to', date)
      },
    },
    selected(): Date[] {
      if (this.isRange) {
        const selected: Date[] = []
        if (this.from) {
          selected.push(this.from)
        }
        if (this.to) {
          selected.push(this.to)
        }
        return selected
      }

      return this.date ? [this.date] : []
    },
  },

  watch: {
    preset: {
      immediate: true,
      handler(preset, oldPreset) {
        if (!preset || preset == oldPreset) {
          return
        }
        this.changeDateRange(preset)
      },
    },
    selected: {
      immediate: true,
      handler(dates: Date[]) {
        if (dates?.length) {
          if (this.isRange) {
            let date = dates.length == 2 ? dates[1] : this.showedDates[1]
            if (isSameMonth(date, dates[0])) {
              date = addMonths(date, 1)
            }
            this.showedDates[0] = new Date(dates[0])
            this.showedDates[1] = new Date(date)
          } else {
            this.showedDates[0] = new Date(dates[0])
          }
        } else {
          this.showedDates[0] = this.getEntityCurrentDate()
        }

        this.oldShowedDates = [...this.showedDates]
      },
    },
    showedDates: {
      handler(dates: Date[]) {
        if (!this.isRange) {
          return
        }

        // left side changed
        if (dates[0] != this.oldShowedDates[0]) {
          if (isAfter(dates[0], dates[1]) || isSameMonth(dates[0], dates[1])) {
            this.showedDates[1] = addMonths(dates[0], 1)
          }
        } else if (
          isBefore(dates[1], dates[0]) ||
          isSameMonth(dates[1], dates[0])
        ) {
          // right side changed
          this.showedDates[0] = addMonths(dates[0], -1)
        }

        // Note: when mutating an Array, the old value will be the same as new value.
        // Vue does not keep a copy of the pre-mutate value.
        this.oldShowedDates = [...this.showedDates]
      },
    },
    from: {
      immediate: true,
      handler(value: Date | null) {
        if (!this.nullable && value && this.initialFrom == null) {
          this.initialFrom = value
        }
      },
    },
    to: {
      immediate: true,
      handler(value: Date | null) {
        if (!this.nullable && value && this.initialTo == null) {
          this.initialTo = value
        }
      },
    },
    date: {
      immediate: true,
      handler(value: Date | null) {
        if (!this.nullable && value && this.initialDate == null) {
          this.initialDate = value
        }
      },
    },
  },

  methods: {
    openPicker() {
      if (!this.disabled) {
        this.setPosition()
        this.showPopup = true
      }
    },
    closePicker() {
      this.showPopup = false
    },
    setPosition() {
      const button = this.$refs.button as HTMLDivElement | undefined
      const rect = button?.getBoundingClientRect()
      if (rect) {
        const wWidth = window.innerWidth
        const lMargin = rect.left
        const rMargin = wWidth - rect.right
        const centerTolerance = 10
        const isCenter = Math.abs(lMargin - rMargin) <= centerTolerance

        this.menuPosition = isCenter
          ? 'center'
          : lMargin > rMargin
            ? 'right'
            : 'left'
      }
    },
    reset() {
      if (this.nullable) {
        if (this.isRange) {
          this.selectedFrom = null
          this.selectedTo = null
        } else {
          this.selectedDate = null
        }
      } else if (this.isRange) {
        if (this.defaultPreset) {
          this.preset = this.defaultPreset
        } else {
          this.selectedFrom = this.initialFrom || this.getEntityCurrentDate()
          this.selectedTo = this.initialTo || this.getEntityCurrentDate()
        }
      } else {
        this.selectedDate = this.initialDate || this.getEntityCurrentDate()
      }

      this.$nextTick(() => {
        this.closePicker()
      })
    },
    selectDate(date: Date) {
      this.preset = null

      if (this.isRange) {
        if (!this.selectedFrom || this.selectedTo) {
          this.selectedFrom = startOfDay(date)
          this.selectedTo = null
          return
        }

        if (isBefore(date, this.selectedFrom)) {
          this.selectedTo = endOfDay(this.selectedFrom)
          this.selectedFrom = startOfDay(date)
        } else {
          this.selectedTo = endOfDay(date)
        }

        this.closePicker()
        return
      }
      this.selectedDate = date
      this.closePicker()
    },
    setDatesFromInput(dates: Date[]) {
      if (this.isRange && dates.length == 2) {
        const [from, to] = dates
        this.selectedFrom = from
        this.selectedTo = to
        this.closePicker()
      } else if (!this.isRange && dates.length == 1) {
        this.selectedDate = dates[0]
        this.closePicker()
      }
    },
    changeDateRange(preset: string) {
      const now = this.getEntityCurrentDate()
      let from: Date | null = null
      let to: Date | null = null

      switch (preset) {
        case 'today':
          from = startOfDay(now)
          to = endOfDay(now)
          break
        case 'yesterday':
          from = startOfDay(subDays(now, 1))
          to = endOfDay(subDays(now, 1))
          break
        case 'tomorrow':
          from = startOfDay(addDays(now, 1))
          to = endOfDay(addDays(now, 1))
          break
        case 'lastWeek':
          const subWeek = subWeeks(now, 1)
          from = startOfDay(startOfWeek(subWeek))
          to = endOfDay(endOfWeek(subWeek))
          break
        case 'lastQuarter':
          const subQuarter = subQuarters(now, 1)
          from = startOfDay(startOfQuarter(subQuarter))
          to = endOfDay(endOfQuarter(subQuarter))
          break
        case 'lastYear':
          const subYear = subYears(now, 1)
          from = startOfDay(startOfYear(subYear))
          to = endOfDay(endOfYear(subYear))
          break
        case 'lastMonth':
          const subMonth = subMonths(now, 1)
          from = startOfDay(startOfMonth(subMonth))
          to = endOfDay(endOfMonth(subMonth))
          break
        case 'nextWeek':
          const addWeek = addWeeks(now, 1)
          from = startOfDay(startOfWeek(addWeek))
          to = endOfDay(endOfWeek(addWeek))
          break
        case 'nextQuarter':
          const addQuarter = addQuarters(now, 1)
          from = startOfDay(startOfQuarter(addQuarter))
          to = endOfDay(endOfQuarter(addQuarter))
          break
        case 'nextYear':
          const addYear = addYears(now, 1)
          from = startOfDay(startOfYear(addYear))
          to = endOfDay(endOfYear(addYear))
          break
        case 'nextMonth':
          const addMonth = addMonths(now, 1)
          from = startOfDay(startOfMonth(addMonth))
          to = endOfDay(endOfMonth(addMonth))
          break
        case 'thisMonth':
          from = startOfDay(startOfMonth(now))
          to = endOfDay(now)
          break
        case 'thisYear':
          from = startOfDay(startOfYear(now))
          to = endOfDay(now)
          break
        case 'allPastTime':
          from = startOfDay(this.allowFrom || new Date(0))
          to = endOfDay(now)
          break
        default:
          from = startOfDay(now)
          to = endOfDay(now)
          break
      }

      if (!this.isDateAllowed(from)) {
        from = this.allowFrom
      }
      if (!this.isDateAllowed(to)) {
        to = this.allowTo
      }

      if (from && to) {
        this.selectedFrom = from
        this.selectedTo = to
        this.closePicker()
      }
    },
  },
})
</script>
