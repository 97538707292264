import useClipboard from 'vue-clipboard3'
import { useEventBus } from '@/composables/event_bus'
import i18n from '@/langs'

export function useCKClipboard() {
  const eventBus = useEventBus()

  const copyText = (text: string, message?: string) => {
    const { toClipboard } = useClipboard()
    toClipboard(text).then(
      () => {
        eventBus.emit('success', {
          message:
            message || i18n.global.t('messages.text_copied_to_clipboard'),
        })
      },
      (e) => {
        console.warn(e)
      }
    )
  }

  return {
    copyText,
  }
}
