import { isEqual } from 'lodash'
import type { CurrencyValue, JSONValue } from '@/types/services/common'

type NonNullObject = {
  [key: string]: JSONValue
}

/**
 * Assign properties from one object to another based on keys from target object.
 *
 * @param {T} targetObject - The object to assign properties to.
 * @param {any} valueObject - The object providing the properties to assign.
 */
export function assignProperties<T>(targetObject: T, valueObject: any) {
  const keys = Object.keys(targetObject) as (keyof T)[]
  keys.forEach((key) => {
    if (key in valueObject) {
      targetObject[key] = valueObject[key]
    }
  })
}

/**
 * Checks if the given value is a non-null object and not an array.
 *
 * @param {JSONValue} data - The value to be checked.
 * @return {boolean} Returns true if the value is a non-null object, false otherwise.
 */
export function isNonNullObject(data: JSONValue): data is NonNullObject {
  return data != null && typeof data === 'object' && !Array.isArray(data)
}

/**
 * Finds the properties that have different values between two objects.
 *
 * @param {object} a - The first object.
 * @param {object} b - The second object.
 * @return {string[]} An array of property names that have different values.
 */
export function findDifferentProperties(a: object, b: object): string[] {
  const allKeysSet = new Set([...Object.keys(a), ...Object.keys(b)])
  return Array.from(allKeysSet).filter((key) => !isEqual(a[key], b[key]))
}

/**
 * Converts an object into a query string.
 *
 * This function takes a record of key-value pairs and returns a string of URL query parameters.
 * It handles arrays and nested objects by recursively processing their values.
 *
 * @param {Record<string, any>} params - The object to convert into a query string.
 * @returns {string} A string of URL query parameters.
 */
export function convertToQueryParams(params: Record<string, any>): string {
  const query: string[] = []

  function processValue(key: string, value: any) {
    if (Array.isArray(value)) {
      value.forEach((val) => processValue(`${key}[]`, val))
    } else if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((subKey) => {
        processValue(`${key}[${String(subKey)}]`, value[subKey])
      })
    } else if (value !== undefined && value !== null) {
      query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }

  Object.keys(params).forEach((key) => {
    processValue(key, params[key])
  })

  return query.join('&')
}

/**
 * Checks if the given object conforms to the CurrencyValue type.
 *
 * @param {any} obj - The object to be checked.
 * @returns {obj is CurrencyValue} True if the object is a CurrencyValue, false otherwise.
 */
export function isCurrencyValue(obj: any): obj is CurrencyValue {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.currency_id === 'string' &&
    typeof obj.value === 'number'
  )
}

/**
 * Checks if the given array conforms to the CurrencyValue[] type.
 *
 * @param {any} arr - The array to be checked.
 * @returns {arr is CurrencyValue[]} True if the array is a CurrencyValue[], false otherwise.
 */
export function isCurrencyValueArray(arr: any): arr is CurrencyValue[] {
  return Array.isArray(arr) && arr.every(isCurrencyValue)
}
