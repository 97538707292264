<template>
  <span v-html="convertTextEmojisToUnicode(releaseNote.note)" />
  <a
    v-if="releaseNote.link"
    :href="releaseNote.link"
    class="text-brand hover:underline ml-1"
    target="_blank"
  >
    <SvgIcon icon="ExternalLink" size="sm" class="relative -top-0.5" />
  </a>
  <template v-if="releaseNote.image">
    <span
      @click="toggleShowScreenshot"
      class="text-brand hover:text-hover cursor-pointer flex items-center"
    >
      {{
        showScreenshot
          ? $t('ui.system.release_notes.hide_screenshot')
          : $t('ui.system.release_notes.show_screenshot')
      }}
      <SvgIcon
        icon="ArrowDown"
        class="ml-1"
        :class="{
          'transform rotate-180': showScreenshot,
        }"
      />
      <Spinner
        class="inline-block h-5 w-5 fill-current"
        :class="{ 'opacity-0': !loadingImage }"
      />
    </span>
    <TransitionFadeExpand>
      <div v-if="showScreenshot">
        <img
          :src="releaseNote.image"
          alt="Image of release note"
          class="my-2 max-w-full"
        />
      </div>
    </TransitionFadeExpand>
  </template>
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue'
import Spinner from '@/assets/loaders/Spinner.svg?component'
import { convertTextEmojisToUnicode } from '@/helpers/strings'
import { TransitionFadeExpand } from '@/components/misc/Transitions'
import SvgIcon from '@/components/misc/SvgIcon'
import type { ReleaseNote } from '@/types/services/system'

const props = defineProps({
  releaseNote: {
    type: Object as PropType<ReleaseNote>,
    required: true,
  },
})

const showScreenshot = ref(false)
const loadingImage = ref(false)

function toggleShowScreenshot() {
  if (!props.releaseNote.image) {
    return
  }

  if (showScreenshot.value) {
    showScreenshot.value = false
  } else {
    loadingImage.value = true
    const image = new Image()
    image.onload = () => {
      loadingImage.value = false
      showScreenshot.value = true
    }
    image.src = props.releaseNote.image
  }
}
</script>
