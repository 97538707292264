<template>
  <transition name="select-items" appear>
    <div
      v-if="collapsed"
      class="crypkit-select-items"
      @click="_collapsed = false"
      @keydown.up.prevent="hoverPrev"
      @keydown.down.prevent="hoverNext"
    >
      <div ref="items-list" class="items-list" :style="style">
        <ul
          ref="items-list-ul"
          @scroll="checkScroll"
          @mouseleave="hoveredIndex = null"
        >
          <template v-if="options.length">
            <SelectItem
              v-for="(option, index) in options"
              :ref="`item-${index}`"
              :key="index"
              :option="option"
              :text-key="textKey"
              :selected-value="selectedValue"
              :group-values="groupValues"
              :option-index="index"
              :hovered-index="hoveredIndex"
              @hovered="hoveredIndex = $event"
              @select="$emit('select', $event)"
            >
              <template #item-text="slotProps">
                <slot name="item-text" :option="slotProps.option" />
              </template>
            </SelectItem>
          </template>
          <li v-else class="no-results">
            <span>{{ noOptionsText }}</span>
          </li>
          <li v-if="$slots && $slots['after-options']">
            <slot name="after-options" />
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import './SelectItems.scss'

import {
  type ComponentPublicInstance,
  defineComponent,
  type PropType,
} from 'vue'

import SelectItem from './SelectItem.vue'

import { type HoveredIndex } from '@/types/components/select'

export default defineComponent({
  components: {
    SelectItem,
  },

  props: {
    options: {
      type: Array as PropType<object[]>,
      required: true,
    },
    textKey: {
      type: String,
      default: 'text',
    },
    selectedValue: {
      type: [Object, Array] as PropType<object | object[] | null>,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    groupValues: {
      type: String,
      default: null,
    },
    noOptionsText: {
      type: String,
      default: 'No results found',
    },
    positionTo: {
      type: Element as PropType<Element | null>,
      default: null,
    },
  },

  emits: ['select', 'update:collapsed', 'load-more'],

  data() {
    return {
      style: {
        left: '0',
        top: '0',
        width: 'auto',
        height: 'auto',
      },
      hoveredIndex: null as HoveredIndex | null,
    }
  },

  computed: {
    _collapsed: {
      get(): boolean {
        return this.collapsed
      },
      set(value: boolean) {
        this.$emit('update:collapsed', value)
      },
    },
  },

  watch: {
    _collapsed(value) {
      if (value) {
        this.setPosition()
      }
      this.setBodyScroll(!value)
    },
    selectedValue() {
      this.hoverSelected()
      this.scrollToItem()
    },
    options() {
      this.hoverSelected()

      this.$nextTick(() => {
        this.setPosition()
      })
    },
  },

  mounted() {
    if (this.collapsed) {
      this.$nextTick(() => {
        this.setPosition()
        this.hoverSelected()
        this.scrollToItem()
      })
    }
    this.setBodyScroll(!this.collapsed)
  },

  beforeMount() {
    this.$nextTick(() => {
      const elm = this.$el
      if (elm) {
        document.body.appendChild(elm)
      }
    })
    window.addEventListener('resize', this.setPosition)
  },

  beforeUnmount() {
    this.setBodyScroll(true)
    window.removeEventListener('resize', this.setPosition)
    const elm = this.$el
    if (elm && document.body.contains(elm)) {
      document.body.removeChild(elm)
    }
  },

  methods: {
    hoverSelected() {
      if (this.selectedValue) {
        if (this.groupValues) {
          for (let i = 0; i < this.options.length; i++) {
            const idx = this.selectedIndexInGroup(
              this.options[i][this.groupValues]
            )
            if (idx != -1) {
              this.setHoverIndex(i, idx)
              return
            }
          }
        } else {
          const idx = this.selectedIndexInGroup(this.options)
          if (idx != -1) {
            this.setHoverIndex(null, idx)
            return
          }
        }
      }
      this.setHoverIndex(null, null)
    },
    selectedIndexInGroup(group: any[]) {
      if (!this.selectedValue) {
        return -1
      }

      return group.indexOf(this.selectedValue)
    },
    checkScroll() {
      const el = this.$refs['items-list-ul'] as HTMLElement | undefined
      if (el && el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
        this.$emit('load-more')
      }
    },
    setPosition() {
      const bodyRect = document.body.getBoundingClientRect()
      const parent = this.positionTo || this.$parent?.$el

      if (!parent) {
        return
      }

      const parentRect = parent.getBoundingClientRect()
      const el = this.$refs['items-list'] as HTMLElement | undefined

      if (!el) {
        return
      }

      const listRect = el.getBoundingClientRect()

      const left = parentRect.left
      let top = parentRect.top + parentRect.height + 4
      let height = 'auto'

      if (top + listRect.height > bodyRect.height) {
        top -= listRect.height + parentRect.height + 8
        if (top < 0) {
          top = 0
          if (listRect.height > bodyRect.height) {
            height = `${bodyRect.height}px`
          }
        }
      }

      this.style.left = `${left}px`
      this.style.top = `${top}px`
      this.style.width = `${parentRect.width}px`
      this.style.height = height
    },
    hoverPrev() {
      if (this.groupValues) {
        if (this.hoveredIndex === null) {
          this.setHoverIndex(this.options.length - 1, 0)
        } else if (this.hoveredIndex.option - 1 < 0) {
          const group =
            this.hoveredIndex.group !== null ? this.hoveredIndex.group : 0
          const prevGroupIdx =
            group - 1 < 0 ? this.options.length - 1 : group - 1
          this.setHoverIndex(
            prevGroupIdx,
            this.options[prevGroupIdx][this.groupValues].length - 1
          )
        } else {
          this.setHoverIndex(
            this.hoveredIndex.group,
            this.hoveredIndex.option - 1
          )
        }
      } else if (this.hoveredIndex === null || this.hoveredIndex.option == 0) {
        this.setHoverIndex(null, this.options.length - 1)
      } else {
        this.setHoverIndex(null, this.hoveredIndex.option - 1)
      }

      this.scrollToItem()
    },
    hoverNext() {
      if (this.groupValues) {
        if (this.hoveredIndex === null) {
          this.setHoverIndex(0, 0)
        } else if (
          this.hoveredIndex.group !== null &&
          this.hoveredIndex.option + 1 >=
            this.options[this.hoveredIndex.group][this.groupValues].length
        ) {
          const nextGroupIdx = this.hoveredIndex.group + 1
          this.setHoverIndex(
            nextGroupIdx >= this.options.length ? 0 : nextGroupIdx,
            0
          )
        } else {
          this.setHoverIndex(
            this.hoveredIndex.group,
            this.hoveredIndex.option + 1
          )
        }
      } else if (
        this.hoveredIndex === null ||
        this.hoveredIndex.option + 1 >= this.options.length
      ) {
        this.setHoverIndex(null, 0)
      } else {
        this.setHoverIndex(null, this.hoveredIndex.option + 1)
      }

      this.scrollToItem()
    },
    setHoverIndex(group: number | null, option: number | null) {
      if (option === null) {
        this.hoveredIndex = null
        return
      }

      if (
        (group !== null && !this.options[group][this.groupValues][option]) ||
        (group === null && !this.options[option])
      ) {
        return
      }

      this.hoveredIndex = {
        group,
        option,
      }
    },
    scrollToItem(): void {
      if (this.hoveredIndex == null) {
        return
      }

      requestAnimationFrame(() => {
        const items = this.$refs['items-list-ul'] as HTMLElement | undefined
        if (items) {
          items.scrollTop = this.calcScrollPosition()
        }
      })
    },
    calcScrollPosition(): number {
      const items = this.$refs['items-list-ul'] as HTMLElement | undefined

      if (!items) {
        return 0
      }

      const maxScrollTop = items.scrollHeight - items.offsetHeight

      let elm = null as HTMLElement | null
      let offsetTop = 0
      if (this.hoveredIndex) {
        if (this.groupValues && this.hoveredIndex.group !== null) {
          elm = (
            (
              this.$refs[
                `item-${this.hoveredIndex.group}`
              ] as ComponentPublicInstance[]
            )[0].$refs[
              `item-${this.hoveredIndex.option}`
            ] as ComponentPublicInstance[]
          )[0].$el as HTMLElement
          offsetTop =
            elm.offsetTop + (elm.parentElement?.parentElement?.offsetTop || 0)
        } else {
          elm = (
            this.$refs[
              `item-${this.hoveredIndex.option}`
            ] as ComponentPublicInstance[]
          )[0].$el as HTMLElement
          offsetTop = elm.offsetTop
        }
      }

      return elm
        ? Math.min(
            maxScrollTop,
            Math.max(
              0,
              offsetTop - items.offsetHeight / 2 + elm.offsetHeight / 2
            )
          )
        : items.scrollTop
    },
    selectHovered(): void {
      const { hoveredIndex, groupValues } = this
      if (hoveredIndex !== null) {
        if (hoveredIndex.group !== null && groupValues) {
          this.$emit(
            'select',
            this.options[hoveredIndex.group][groupValues][hoveredIndex.option]
          )
        } else {
          this.$emit('select', this.options[hoveredIndex.option])
        }
        this.clearHovered()
      }
    },
    hasHovered(): boolean {
      return this.hoveredIndex !== null
    },
    clearHovered(): void {
      this.hoveredIndex = null
    },
    setBodyScroll(enabled: boolean): void {
      if (enabled) {
        document.body.style.overflow = ''
        document.body.style.paddingRight = ''
      } else {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth
        if (scrollbarWidth > 0) {
          document.body.style.paddingRight = `${scrollbarWidth}px`
        }
        document.body.style.overflow = 'hidden'
      }
    },
  },
})
</script>
