<template>
  <Modal :show="showModal" hide-buttons size="md" @close="showModal = false">
    <BoxedSvgIcon icon="ReleaseNotes" class="-mt-16 mb-2" />
    <div class="text-center text-lg font-semibold">
      {{ $t('ui.system.release_notes.added_new_features') }}
    </div>
    <hr class="my-4 -mx-5" />
    <div
      v-for="versionNotes in lastTwoNotes"
      :key="versionNotes.version"
      class="mb-8"
    >
      <VersionReleaseNotes :version-notes="versionNotes" />
    </div>

    <div class="flex justify-end">
      <Button @click="showModal = false">
        {{ $t('ui.common.ok_thanks') }}
      </Button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useEventBus } from '@/composables/event_bus'
import { useSystemStore } from '@/stores/system'
import { useUserStore } from '@/stores/user'
import Button from '@/components/controls/Button'
import Modal from '@/components/misc/Modal'
import { BoxedSvgIcon } from '@/components/misc/SvgIcon'
import VersionReleaseNotes from './VersionReleaseNotes.vue'
import { releaseVersion } from '@/helpers/system'

const eventBus = useEventBus()
const systemStore = useSystemStore()
const userStore = useUserStore()

const showModal = ref(false)

const lastTwoNotes = computed(() => {
  return systemStore.release_notes.data.slice(0, 2)
})

async function getData() {
  try {
    await systemStore.getLatestVersion()

    // do not show release notes until user has installed the latest version
    if (
      systemStore.latest_version.data === null ||
      systemStore.latest_version.data.version !== releaseVersion()
    ) {
      return
    }

    await systemStore.getReleaseNotes(
      userStore.settings.additional.last_read_release_notes_version
    )

    if (systemStore.release_notes.data.length > 0) {
      if (userStore.settings.additional.last_read_release_notes_version) {
        showModal.value = true
      }

      await userStore.saveUserAdditionalSettings({
        last_read_release_notes_version:
          systemStore.release_notes.data[0].version,
      })
    }
  } catch (err) {
    eventBus.emit('error', err)
  }
}

onMounted(() => {
  getData()
})
</script>
